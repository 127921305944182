import { HeartIcon } from '@heroicons/react/24/outline'
import { HeartIcon as HeartIconSolid } from '@heroicons/react/24/solid'
import { QueryObserverResult, RefetchOptions, RefetchQueryFilters } from '@tanstack/react-query'
import { useCallback, useMemo, useState } from 'react'

import { ManagedDialog } from '@/shared/constants'
import { useDialog } from '@/shared/hooks'
import { useDeleteLikeItem, usePostLikeItem } from '@/shared/services/items'
import { useAppSelector } from '@/shared/store/hooks'
import { selectCurrentAccountAddress } from '@/shared/store/wallet'

interface IItemLikeProps {
  tokenId: string
  isLike: boolean
  likeCount: number
  collectionAddress: string
  requestRefetchCheckLike: <TPageData>(
    options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined,
  ) => Promise<
    QueryObserverResult<
      {
        isLike: boolean
      },
      unknown
    >
  >
}
function ItemDetailLike({
  isLike,
  tokenId,
  likeCount,
  collectionAddress,
  requestRefetchCheckLike,
}: IItemLikeProps) {
  const { setDialog } = useDialog()
  const { mutate: requestLikeItem } = usePostLikeItem()
  const { mutate: requestUnlikeItem } = useDeleteLikeItem()

  const isLikeStatus = useMemo(() => isLike, [isLike])
  const [currentLikeCount, setCurrentLikeCount] = useState(likeCount)
  const currentAccountAddress = useAppSelector(selectCurrentAccountAddress)

  const handleConnectWalletDialogOpen = useCallback(() => {
    setDialog(ManagedDialog.connectWallet)
  }, [setDialog])

  const handleClickLikeButton = useCallback(() => {
    if (currentAccountAddress === null) {
      handleConnectWalletDialogOpen()
      return
    }

    if (isLike) {
      requestUnlikeItem(
        { itemId: `${collectionAddress}-${tokenId}` },
        {
          onSuccess: async () => {
            await requestRefetchCheckLike()
            setCurrentLikeCount(prev => (isLike ? prev - 1 : prev + 1))
          },
        },
      )
      return
    }

    requestLikeItem(
      { itemId: `${collectionAddress}-${tokenId}` },
      {
        onSuccess: async () => {
          await requestRefetchCheckLike()
          setCurrentLikeCount(prev => (isLike ? prev - 1 : prev + 1))
        },
      },
    )
  }, [
    isLike,
    tokenId,
    requestLikeItem,
    requestUnlikeItem,
    collectionAddress,
    currentAccountAddress,
    requestRefetchCheckLike,
    handleConnectWalletDialogOpen,
  ])

  return (
    <>
      <button onClick={handleClickLikeButton}>
        {isLikeStatus ? <HeartIconSolid className="w-6" /> : <HeartIcon className="w-6" />}
      </button>
      <div>{currentLikeCount}</div>
    </>
  )
}

export default ItemDetailLike
