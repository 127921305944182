import { ReactNode } from 'react'

import { cx } from '@/shared/utils'

interface IItemSectionCardProps {
  children: ReactNode
  className?: string
}
interface IItemSectionCardTitleProps {
  Icon?: () => JSX.Element
  title: string
  className?: string
}

function ItemSectionCard({ children, className = '' }: IItemSectionCardProps) {
  return (
    <div
      className={cx(
        'px-4 lg:py-6 lg:px-5 py-5 lg:border-borderQuarternary lg:border-solid lg:border-[1px] rounded-[20px] min-h-[480px]',
        className,
      )}>
      {children}
    </div>
  )
}

function Title({ Icon, title, className }: IItemSectionCardTitleProps) {
  return (
    <div className={cx('flex gap-x-[8px] font-semibold text-lg items-center mb-4', className)}>
      {Icon ? <Icon /> : null}
      <div>{title}</div>
    </div>
  )
}
ItemSectionCard.Title = Title

export default ItemSectionCard
