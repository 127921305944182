/* eslint-disable @next/next/no-img-element */
import { QueryObserverResult, RefetchOptions, RefetchQueryFilters } from '@tanstack/react-query'
import { useCallback } from 'react'
import CopyToClipboard from 'react-copy-to-clipboard'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

import { ManagedDialog } from '@/shared/constants'
import { useBreakpoint, useDialog, useTransferCountInfo } from '@/shared/hooks'
import { useAppDispatch } from '@/shared/store/hooks'
import { transferDialogOpen } from '@/shared/store/transfer'
import { CrawlingStatus, IItemDetail, IListing, IOffer } from '@/shared/types'
import { checkIsTradable, checkIsTransferable, cx, isCheckVideoFileExtension } from '@/shared/utils'

import { PriceLabel, Video } from '../common'
import { Button } from '../core-ui'
import ItemDetailLike from './ItemDetailLike'

interface IItemDetailCardProps {
  className?: string

  item: IItemDetail
  collectionAddress: string
  tokenId: string

  isLike: boolean
  isOwner: boolean
  isListing: boolean
  isConnectedWallet: boolean

  itemStatus: CrawlingStatus | null

  handleOpenEnlarge: () => void

  handleCreateListing: () => void
  handleCreateOffer: () => void
  handlePurchase: () => void
  handleClickEditOrCancelOfferDialog: () => void
  handleClickEditOrCancelListingDialog: () => void
  requestRefetchCheckLike: <TPageData>(
    options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined,
  ) => Promise<
    QueryObserverResult<
      {
        isLike: boolean
      },
      unknown
    >
  >

  listingPriceConvertedToCoinPrice: string | null
  lowestPriceListing?: IListing | null
  lowestPriceListingStatus: 'loading' | 'success' | 'error'
  currentAccountHighestPriceOffer?: IOffer | null
  currentAccountHighestPriceOfferStatus: 'loading' | 'success' | 'error'
}

/**
 * 판매자
 *
 * (1) 판매전 => 전송하기, 판매하기
 * (2) 판매중 => 전송하기, 판매 수정하기
 */
function SellerButton({
  item,
  isListing,
  handleCreateListing,
  handleClickEditOrCancelListingDialog,
}: {
  isListing: boolean
  item: IItemDetail
  handleCreateListing: () => void
  handleClickEditOrCancelListingDialog: () => void
}) {
  const { t } = useTranslation('common')
  const isTransferable = checkIsTransferable(item)
  const isTradable = checkIsTradable(item)

  const dispatch = useAppDispatch()
  const { isLG } = useBreakpoint()

  const { handleSpecificDialog, setIsPreparingToOpenDialog } = useDialog()

  const { isExceedTransferCountByItem } = useTransferCountInfo(item)

  const handlePreparingToOpenDialog = useCallback(
    (isOpen: boolean) => setIsPreparingToOpenDialog(isOpen),
    [setIsPreparingToOpenDialog],
  )
  const handleTransferFormDialog = handleSpecificDialog(ManagedDialog.transferForm)
  const handleExceedTransferDialog = handleSpecificDialog(ManagedDialog.exceedTransfer)

  const handleTransfer = useCallback(() => {
    if (isExceedTransferCountByItem) {
      handleExceedTransferDialog(true)
      return
    }

    dispatch(
      transferDialogOpen({
        collectionTitle: item.collection.title,
        itemTitle: item.title,
        itemImage: item.mediaUrl,
        collectionAddress: item.collection.collectionAddress,
        tokenId: item.tokenId.toString(),
        chainId: item.collection.chainId,
        handlePreparingToOpenDialog,
        handleTransferFormDialog,
      }),
    )
  }, [
    dispatch,
    item.title,
    item.tokenId,
    item.mediaUrl,
    item.collection.title,
    item.collection.chainId,
    handleTransferFormDialog,
    handleExceedTransferDialog,
    isExceedTransferCountByItem,
    handlePreparingToOpenDialog,
    item.collection.collectionAddress,
  ])

  return (
    <>
      {!isListing && (
        <>
          <Button
            size={isLG ? 'lg' : 'sm'}
            theme="Filled"
            text={t('common:common.item-transfer')}
            disable={!isTransferable}
            onClick={handleTransfer}
          />
          <Button
            size={isLG ? 'lg' : 'sm'}
            theme="Accent"
            text={t('common:common.cta-label-sale')}
            disable={!isTradable}
            onClick={handleCreateListing}
          />
        </>
      )}
      {isListing && (
        <>
          <Button
            size={isLG ? 'lg' : 'sm'}
            theme="Filled"
            text={t('common:common.item-transfer')}
            disable={!isTransferable}
            onClick={handleTransfer}
          />
          <Button
            size={isLG ? 'lg' : 'sm'}
            theme="Accent"
            text={t('common:common.item-edit-sale')}
            disable={!isTradable}
            onClick={handleClickEditOrCancelListingDialog}
          />
        </>
      )}
    </>
  )
}

/**
 * 구매자
 *
 * (1) 제안 중 && 판매중 => 제안 수정하기, 구매하기
 * (2) 제안 전 && 판매중 => 제안하기, 구매하기
 * (3) 제안전 && 판매중이지 않음 => 공유하기, 제안하기
 * (4) 제안 중 && 판매중이지 않음 => 공유하기, 제안수정하기
 */
function BuyerButton({
  item,
  shareUrl,
  currentAccountHighestPriceOffer,
  lowestPriceListing,
  handlePurchase,
  handleCreateOffer,
  handleClickEditOrCancelOfferDialog,
}: {
  shareUrl: string
  item: IItemDetail
  currentAccountHighestPriceOffer: IOffer | null | undefined
  lowestPriceListing: IListing | null | undefined
  handlePurchase: () => void
  handleCreateOffer: () => void
  handleClickEditOrCancelOfferDialog: () => void
}) {
  const { t } = useTranslation(['common', 'web-view-item'])
  const { isLG } = useBreakpoint()

  const isTradable = checkIsTradable(item)

  return (
    <>
      {lowestPriceListing && currentAccountHighestPriceOffer && (
        <>
          <Button
            size={isLG ? 'lg' : 'sm'}
            theme="Filled"
            text={t('common:common.cta-label-edit-offer')}
            disable={!isTradable}
            onClick={handleClickEditOrCancelOfferDialog}
          />
          <Button
            size={isLG ? 'lg' : 'sm'}
            theme="Accent"
            text={t('common:common.cta-transaction-buy')}
            disable={!isTradable}
            onClick={handlePurchase}
          />
        </>
      )}
      {lowestPriceListing && !currentAccountHighestPriceOffer && (
        <>
          <Button
            size={isLG ? 'lg' : 'sm'}
            theme="Filled"
            text={t('common:common.cta-label-offer')}
            disable={!isTradable}
            onClick={handleCreateOffer}
          />
          <Button
            size={isLG ? 'lg' : 'sm'}
            theme="Accent"
            text={t('common:common.cta-transaction-buy')}
            onClick={handlePurchase}
          />
        </>
      )}
      {!lowestPriceListing && !currentAccountHighestPriceOffer && (
        <>
          <CopyToClipboard
            text={shareUrl}
            onCopy={() =>
              toast(t('common:common.toast-copied-wallet-address'), {
                toastId: 'share',
              })
            }>
            <Button
              size={isLG ? 'lg' : 'sm'}
              theme="Filled"
              text={t('common:common.cta-label-share')}
            />
          </CopyToClipboard>
          <Button
            size={isLG ? 'lg' : 'sm'}
            theme="Accent"
            text={t('common:common.cta-label-offer')}
            disable={!isTradable}
            onClick={handleCreateOffer}
          />
        </>
      )}
      {!lowestPriceListing && currentAccountHighestPriceOffer && (
        <>
          <CopyToClipboard
            text={shareUrl}
            onCopy={() =>
              toast(t('common:common.toast-copied-wallet-address'), {
                toastId: 'share',
              })
            }>
            <Button
              size={isLG ? 'lg' : 'sm'}
              theme="Filled"
              text={t('common:common.cta-label-share')}
            />
          </CopyToClipboard>
          <Button
            size={isLG ? 'lg' : 'sm'}
            theme="Accent"
            text={t('common:common.cta-label-edit-offer')}
            disable={!isTradable}
            onClick={handleClickEditOrCancelOfferDialog}
          />
        </>
      )}
    </>
  )
}

function ButtonSkeleton() {
  const { isLG } = useBreakpoint()
  return (
    <>
      <Button size={isLG ? 'lg' : 'sm'} theme="Filled" />
      <Button size={isLG ? 'lg' : 'sm'} theme="Accent" />
    </>
  )
}

function NotLoggedInButton({
  shareUrl,
  lowestPriceListing,
}: {
  shareUrl: string
  lowestPriceListing: IListing | null | undefined
}) {
  const { isLG } = useBreakpoint()
  const { setDialog } = useDialog()
  const { t } = useTranslation(['common', 'web-view-item'])

  const handleConnectWalletDialogOpen = useCallback(() => {
    setDialog(ManagedDialog.connectWallet)
  }, [setDialog])

  return (
    <>
      {lowestPriceListing ? (
        <Button
          size={isLG ? 'lg' : 'sm'}
          theme="Filled"
          text={t('common:common.cta-label-offer')}
          onClick={handleConnectWalletDialogOpen}
        />
      ) : (
        <CopyToClipboard
          text={shareUrl}
          onCopy={() =>
            toast(t('common:common.toast-copied-wallet-address'), {
              toastId: 'share',
            })
          }>
          <Button
            size={isLG ? 'lg' : 'sm'}
            theme="Filled"
            text={t('common:common.cta-label-share')}
          />
        </CopyToClipboard>
      )}
      {lowestPriceListing ? (
        <Button
          size={isLG ? 'lg' : 'sm'}
          theme="Accent"
          text={t('common:common.cta-transaction-buy')}
          onClick={handleConnectWalletDialogOpen}
        />
      ) : (
        <Button
          size={isLG ? 'lg' : 'sm'}
          theme="Accent"
          text={t('common:common.cta-label-offer')}
          onClick={handleConnectWalletDialogOpen}
        />
      )}
    </>
  )
}

const ItemDetailCard = ({
  className,
  item,
  requestRefetchCheckLike,
  collectionAddress,
  tokenId,
  isLike,
  isOwner,
  isListing,
  isConnectedWallet,
  itemStatus,
  handleOpenEnlarge,
  handleCreateListing,
  handleCreateOffer,
  handlePurchase,
  handleClickEditOrCancelOfferDialog,
  handleClickEditOrCancelListingDialog,
  listingPriceConvertedToCoinPrice,
  lowestPriceListing,
  lowestPriceListingStatus,
  currentAccountHighestPriceOffer,
  currentAccountHighestPriceOfferStatus,
}: IItemDetailCardProps) => {
  const mediaUrl = item.mediaUrl
  const { t } = useTranslation(['common', 'web-view-item'])
  const shareUrl = `${window.location.origin}/item/${collectionAddress}/${tokenId}`

  return (
    <div className="mx-4">
      <div
        className={cx(
          className,
          'max-w-[500px] mx-auto rounded-3xl flex flex-col text-textSecondary bg-bgTertiary overflow-hidden',
        )}>
        {isCheckVideoFileExtension(mediaUrl) ? (
          <Video mediaUrl={mediaUrl} />
        ) : (
          <img
            src={mediaUrl}
            alt="item-image"
            onClick={handleOpenEnlarge}
            className="w-full object-contain aspect-square"
            onError={e => (e.currentTarget.src = '/img/placeholder/item-card-replace.png')}
          />
        )}

        <div className="flex items-center justify-center p-4  text-xs">
          {item.listing ? (
            <div className="w-full flex justify-between">
              <div className="flex flex-col gap-1">
                <p>{t('common:common.label-price')}</p>
                <p className="text-lg text-appTextPrimary font-semibold leading-[23px]">
                  {t('common:common.label-abt')} {listingPriceConvertedToCoinPrice}
                </p>
                <PriceLabel
                  size={'xs'}
                  isShowPaymentType
                  price={item.listing?.price}
                  paymentType={item.listing.paymentType}
                  priceTextStyle="text-appTextTertiary font-medium leading-[15px]"
                />
              </div>
              {!isOwner && (
                <div className="flex flex-col items-center justify-center">
                  <ItemDetailLike
                    isLike={isLike}
                    tokenId={tokenId}
                    likeCount={item.likeCount}
                    collectionAddress={collectionAddress}
                    requestRefetchCheckLike={requestRefetchCheckLike}
                  />
                </div>
              )}
            </div>
          ) : (
            <div className="flex justify-between items-center w-full">
              <div className="lg:text-xl text-base">{t('web-view-item:view-item.not-on-sale')}</div>

              {!isOwner && (
                <div className="flex flex-col items-center justify-center">
                  <ItemDetailLike
                    isLike={isLike}
                    tokenId={tokenId}
                    likeCount={item.likeCount}
                    collectionAddress={collectionAddress}
                    requestRefetchCheckLike={requestRefetchCheckLike}
                  />
                </div>
              )}
            </div>
          )}
        </div>

        {itemStatus !== 'ALCHEMY' && (
          <div className="p-[16px] lg:pt-2 lg:pb-6 lg:px-6 gap-x-[8px] lg:gap-x-[12px] flex fixed lg:relative left-[0] bottom-[0] w-[100vw] lg:w-full text-textPrimary bg-bgPrimary lg:bg-inherit">
            {!isConnectedWallet ? (
              <NotLoggedInButton shareUrl={shareUrl} lowestPriceListing={lowestPriceListing} />
            ) : (
              <>
                {lowestPriceListingStatus === 'loading' ||
                currentAccountHighestPriceOfferStatus === 'loading' ? (
                  <ButtonSkeleton />
                ) : (
                  <>
                    {isOwner ? (
                      <SellerButton
                        isListing={isListing}
                        item={item}
                        handleClickEditOrCancelListingDialog={handleClickEditOrCancelListingDialog}
                        handleCreateListing={handleCreateListing}
                      />
                    ) : (
                      <BuyerButton
                        item={item}
                        shareUrl={shareUrl}
                        lowestPriceListing={lowestPriceListing}
                        currentAccountHighestPriceOffer={currentAccountHighestPriceOffer}
                        handlePurchase={handlePurchase}
                        handleCreateOffer={handleCreateOffer}
                        handleClickEditOrCancelOfferDialog={handleClickEditOrCancelOfferDialog}
                      />
                    )}
                  </>
                )}
              </>
            )}
          </div>
        )}
      </div>
    </div>
  )
}

export default ItemDetailCard
