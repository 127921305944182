import { useRouter } from 'next/router'
import { useMemo } from 'react'

import { cx } from '@/shared/utils'

interface IPageLinkProps {
  title: string
  routeName: string
  className?: string
  handleNavigate: () => void
}

const PageLink = ({ title, routeName, className, handleNavigate }: IPageLinkProps) => {
  const { pathname } = useRouter()

  const isActive = useMemo(() => {
    return pathname.includes(routeName)
  }, [pathname, routeName])

  return (
    <div
      onClick={handleNavigate}
      className={cx(
        isActive ? 'text-textPrimary shadow-underLine' : 'text-textSecondary',
        className,
        'shrink-0 ',
      )}>
      <p className="font-medium py-5 px-3 hover:text-textPrimary cursor-pointer hidden md:block">
        {title}
      </p>
    </div>
  )
}

export default PageLink
