import { ArrowRightCircleIcon, ArrowTopRightOnSquareIcon } from '@heroicons/react/24/outline'
import dayjs from 'dayjs'
import Image from 'next/image'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { i18n } from 'next-i18next'
import { Fragment, useCallback, useMemo, useRef } from 'react'
import { useTranslation } from 'react-i18next'

import { ITEM_DETAIL_TAB, ROUTE_PATH, TRADE_TYPE } from '@/shared/constants'
import { useBalance, useBreakpoint, useIntersectionObserver } from '@/shared/hooks'
import { useItemDetailActivitiesInfiniteQuery } from '@/shared/services/collections'
import { TradeType } from '@/shared/types'
import {
  convertBalanceToCurrencyWithSymbol,
  cx,
  getChainscanUrl,
  shortenAddress,
} from '@/shared/utils'
import { differentDateTime } from '@/shared/utils/timeFormatter'

import { PriceLabel } from '../common'
import { Spinner } from '../core-ui'
import ItemSectionCard from './ItemSectionCard'

function ItemDetailActivity({ className }: { className?: string }) {
  const { query, pathname } = useRouter()
  const { tokenId, collectionAddress } = query as { tokenId?: string; collectionAddress?: string }
  const { allCoinPrice: coinPrice, currency } = useBalance()
  const { t } = useTranslation(['common', 'web-view-item'])
  const locales = i18n?.language ?? 'ko'
  dayjs.locale(locales)

  const now =
    locales === 'ko'
      ? dayjs().format('YYYY년 MM월 DD일 A h:mm')
      : dayjs().format('YYYY.MM.DD A h:mm')

  const { isLG } = useBreakpoint()

  const targetRef = useRef<HTMLDivElement>(null)

  const {
    data: activityDataList,
    status: activitiesStatus,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
    refetch: activityDataListRefetch,
  } = useItemDetailActivitiesInfiniteQuery(pathname, {
    collectionAddress: collectionAddress,
    tokenId: tokenId,
    query: 'page',
  })

  useIntersectionObserver({
    onIntersect: () => {
      !isFetchingNextPage && hasNextPage && fetchNextPage()
    },
    targetRef,
  })

  const isValidTradeForProcessing = useMemo(
    () =>
      activityDataList?.pages[0].lastTradePrice !== null &&
      activityDataList?.pages[0].lastTradePaymentType !== null &&
      (activityDataList?.pages[0].activities[0]?.tradeType === TRADE_TYPE.LISTING_SALE ||
        activityDataList?.pages[0].activities[0]?.tradeType === TRADE_TYPE.OFFER_SALE),
    [activityDataList],
  )

  const lastActivity = useMemo(() => {
    const isEmptyActivityDataList =
      activityDataList === undefined || activityDataList.pages[0].activities.length === 0

    if (isEmptyActivityDataList) return ''

    const lastActivityPrice = activityDataList.pages[0].lastTradePrice
    const lastActivityPaymentType = activityDataList.pages[0].lastTradePaymentType
    const lastActivityTradeType = activityDataList.pages[0].activities[0]?.tradeType
    const lastToAccountAddress = activityDataList.pages[0].activities[0].toAccountAddress

    if (
      isValidTradeForProcessing &&
      lastActivityPrice !== null &&
      lastActivityPaymentType !== null
    ) {
      const convertedPrice = convertBalanceToCurrencyWithSymbol({
        currency,
        coinPrice,
        price: lastActivityPrice?.toString(),
        paymentType: lastActivityPaymentType,
      })

      return `${t('web-view-item:view-item.transaction-history-description').replace(
        '{value}',
        `${lastActivityPrice}${lastActivityPaymentType} (${convertedPrice})`,
      )}`
    }

    if (lastActivityTradeType === 'TRANSFER' && activityDataList) {
      return `${t('web-view-item:view-item.transfer-history-description').replace(
        '{value}',
        `${shortenAddress(lastToAccountAddress)}`,
      )}`
    }

    if (lastActivityTradeType === 'MINTING' && activityDataList) {
      return `${t('web-view-item:view-item.transaction-history-description-first-issued').replace(
        '{value}',
        `${shortenAddress(lastToAccountAddress)}`,
      )}`
    }

    return ''
  }, [activityDataList, coinPrice, currency, isValidTradeForProcessing, t])

  const getTradeType = useCallback(
    (tradeType: TradeType) => {
      const tradeTypeNameMappings = {
        [TRADE_TYPE.LISTING]: `${t('common:common.label-register-for-sale')}`,
        [TRADE_TYPE.OFFER]: `${t('common:common.label-register-offer')}`,
        [TRADE_TYPE.LISTING_SALE]: `${t('common:common.label-sales-succeeded')}`,
        [TRADE_TYPE.OFFER_SALE]: `${t('common:common.label-offer-succeeded')}`,
        [TRADE_TYPE.LISTING_CANCEL]: `${t('common:common.label-offer-succeeded')}`,
        [TRADE_TYPE.OFFER_CANCEL]: `${t('common:common.label-cancel-offer')}`,
        [TRADE_TYPE.TRANSFER]: `${t('common:common.label-transfer')}`,
        [TRADE_TYPE.MINTING]: `${t('common:common.label-minting')}`,
      }
      return tradeTypeNameMappings[tradeType]
    },
    [t],
  )

  return (
    <ItemSectionCard className={className}>
      <div className="hidden lg:block">
        <ItemSectionCard.Title
          title={ITEM_DETAIL_TAB.activity}
          Icon={() => (
            <Image width={24} height={24} src="/img/icon/document.svg" alt="document icon" />
          )}
        />
      </div>
      <div className="min-h-[250px]">
        {lastActivity && (
          <div className="mt-[16px] md:py-5 py-4 px-[16px] bg-bgTertiary rounded-[12px]">
            <div className="font-medium md:text-base text-sm">{lastActivity}</div>

            <div className="mt-[4px] text-textSecondary md:text-sm text-xs">
              {t('web-view-item:view-item.transaction-history-description-date-2').replace(
                '{value}',
                now,
              )}
            </div>
          </div>
        )}

        {activitiesStatus === 'success' && (
          <>
            {activityDataList?.pages[0].activities.length === 0 ? (
              <div className="mt-20 text-textQuarternary text-center">
                {t('common:common.empty-transaction-history')}
              </div>
            ) : (
              <div className="py-[10.5px] lg:mt-6 mt-3 border-b-[1px] border-b-hcPaletteGray200 lg:border-t-[2px] border-solid lg:border-t-bgQuarternary grid lg:grid-cols-itemDetailActivity grid-cols-itemDetailActivityMobile items-center text-textSecondary text-sm">
                <div className="hidden lg:block text-left px-[8px]">
                  {t('common:common.label-classification')}
                </div>

                <div className="text-left px-[4px]">{t('common:common.label-trader')}</div>

                <div className="text-right px-[8px]">{t('common:common.label-trade-price')}</div>
                <div className="text-right px-[8px]">{t('common:common.label-time')}</div>
              </div>
            )}

            <div className="h-[280px] overflow-y-auto">
              {activityDataList?.pages.map(({ activities }, activityDataListIndex: number) => (
                <div
                  className="grid lg:grid-cols-itemDetailActivity grid-cols-itemDetailActivityMobile"
                  key={activityDataListIndex}>
                  <Fragment key={`item-detail-activity-${activityDataListIndex}}`}>
                    {activities.map((activityData, activityDataIndex) => (
                      <Fragment key={`item-detail-activity-${activityDataIndex}}`}>
                        <div className="hidden lg:block h-[56px] py-[16px]">
                          <div
                            className={cx(
                              'p-[4px] ml-2 text-xs font-medium bg-bgTertiary rounded-[4px] leading-[18px] text-textSecondary ',
                              activityData.tradeType === TRADE_TYPE.LISTING_SALE
                                ? 'text-[#7C94E8]'
                                : '',
                              activityData.tradeType === TRADE_TYPE.OFFER_SALE
                                ? 'text-[#E1745C]'
                                : '',
                            )}>
                            {getTradeType(activityData.tradeType)}
                          </div>
                        </div>

                        <div className="h-[56px] py-[16px] px-[4px] flex justify-start">
                          <div className="flex px-[8px] py-[3.5px] items-center bg-zinc-800 rounded-[40px] text-sm  justify-between">
                            {activityData.tradeType === TRADE_TYPE.MINTING ? (
                              <div>{shortenAddress(activityData.collectionAddress, !isLG)}</div>
                            ) : (
                              <div>
                                <Link
                                  href={ROUTE_PATH.ACCOUNT.replace(
                                    ':accountAddress',
                                    activityData.fromAccountAddress,
                                  )}>
                                  <div>
                                    {shortenAddress(activityData.fromAccountAddress, !isLG)}
                                  </div>
                                </Link>
                              </div>
                            )}
                            <ArrowRightCircleIcon className="w-4 h-4" />
                            <Link
                              href={ROUTE_PATH.ACCOUNT.replace(
                                ':accountAddress',
                                activityData.toAccountAddress,
                              )}>
                              <div>{shortenAddress(activityData.toAccountAddress, !isLG)}</div>
                            </Link>
                          </div>
                        </div>

                        {activityData.tradeType === TRADE_TYPE.MINTING ||
                        activityData.tradeType === TRADE_TYPE.TRANSFER ? (
                          <div className="text-sm font-extrabold flex justify-end items-center p-2">
                            <div>-</div>
                          </div>
                        ) : (
                          <div className="flex justify-end">
                            <PriceLabel
                              size="sm"
                              isShowPaymentType
                              price={Number(activityData.order?.price)}
                              paymentType={activityData.order.paymentType}
                            />
                          </div>
                        )}

                        <div className="h-[56px] py-[15px] px-[4px] flex justify-end items-center text-sm">
                          <div className="flex text-textSecondary gap-x-[2px] justify-center items-center">
                            {activityData.tradeType === TRADE_TYPE.MINTING ||
                            activityData.tradeType === TRADE_TYPE.TRANSFER
                              ? differentDateTime({ itemDateTime: activityData.occurredAt, t })
                              : differentDateTime({ itemDateTime: activityData.occurredAt, t })}
                            <div
                              onClick={() => {
                                if (window !== undefined) {
                                  window.location.href = `${getChainscanUrl(
                                    activityDataList.pages[0].activities[0].item.chainId,
                                  )}/tx/${activityData.transactionHash}`
                                }
                              }}>
                              <ArrowTopRightOnSquareIcon className="w-5 h-5" />
                            </div>
                          </div>
                        </div>
                      </Fragment>
                    ))}
                  </Fragment>
                </div>
              ))}
              {hasNextPage && (
                <>
                  <div ref={targetRef}></div>
                </>
              )}
            </div>
          </>
        )}
        {activitiesStatus === 'loading' && (
          <div className="mt-[50%]">
            <Spinner />
          </div>
        )}
        {activitiesStatus === 'error' && (
          <div className="mt-20 flex flex-col gap-3 items-center justify-center">
            <div className="text-textSecondary">{t('common:error-data')}</div>
            <button
              className="py-[10px] px-3 bg-bgQuarternary rounded-sm text-sm text-textPrimary"
              onClick={() => {
                activityDataListRefetch()
              }}>
              {t('common:common.cta-label-try-again')}
            </button>
          </div>
        )}
      </div>
    </ItemSectionCard>
  )
}

export default ItemDetailActivity
