/* eslint-disable @next/next/no-img-element */
import Link from 'next/link'
import { useTranslation } from 'next-i18next'
import React, { useState } from 'react'
import { Fragment } from 'react'
import HTMLEllipsis from 'react-lines-ellipsis/lib/html'

import { useBreakpoint } from '@/shared/hooks'
import { IGetCollectionResponse } from '@/shared/types'
import { numberFormat } from '@/shared/utils'

import { PriceLabel } from '../common'

export interface ICollectionDetailInfoProps {
  collection: IGetCollectionResponse
}

const CollectionDetailInfo: React.FC<ICollectionDetailInfoProps> = ({
  collection,
}: ICollectionDetailInfoProps) => {
  const { t } = useTranslation(['common', 'web-collection'])
  const [isShowMore, setIsShowMore] = useState(false)
  const [clamped, setClamped] = useState(false)
  const { isLG } = useBreakpoint()

  const backgroundImage = isLG
    ? `linear-gradient(180deg, rgba(24, 25, 26, 0.2) 0%, #18191A 62.76%), url(${collection.backgroundImgUrl})`
    : `linear-gradient(180deg, rgba(24, 25, 26, 0.2) 0%, #18191A 72.76%), url(${collection.backgroundImgUrl})`

  return (
    <div
      style={{
        backgroundImage,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
      }}
      className="flex flex-col w-screen lg:w-full pb-6 relative">
      <div className="pt-10 lg:pt-16 z-20">
        <div className="flex flex-col lg:flex-row">
          <div className="relative h-20 lg:h-[180px] w-20 lg:w-[180px] rounded-full bg-[#505057] ml-4 lg:ml-6">
            {collection.isOriginals && (
              <img
                className="absolute left-1 top-1"
                alt="collection_ranking_thumbnail_logo"
                src="/img/badge/originals-badge.svg"
              />
            )}
            <img
              className="h-20 lg:h-[180px] w-20 lg:w-[180px] rounded-full object-cover"
              onError={e => {
                e.currentTarget.src = '/img/placeholder/collection-avatar-replace.png'
              }}
              src={collection.imgUrl || '/img/placeholder/collection-avatar-replace.png'}
              alt="test"
            />
          </div>
          <div className="mt-4 lg:mt-0 lg:px-[0] lg:ml-[64px] pt-0 lg:pt-4">
            <div className="px-[16px] w-full lg:px-[0] text-[24px] lg:text-[36px] font-extrabold text-textPrimary break-all">
              {collection.title}
            </div>
            <div className="flex px-[16px] lg:px-[0] w-11/12 overflow-hidden mt-[2px] lg:mt-[4px] items-center gap-x-[6px] lg:gap-x-[4px]">
              {collection.accountAddress === '' ? (
                <span className="text-base font-medium text-textSecondary">
                  No Collection Owner
                </span>
              ) : (
                <>
                  <span className="text-base font-medium text-textSecondary">by&nbsp;</span>
                  <Link
                    className="text-hcPaletteYellow500 truncate"
                    href={`/account/${collection.accountAddress}`}>
                    {collection.accountAddress}
                  </Link>
                </>
              )}
            </div>
            {collection.description !== '' && (
              <div
                className={
                  isShowMore
                    ? 'mx-[16px] lg:mx-0 relative inline-block mb-[20px] lg:mb-0'
                    : 'mx-[16px] lg:mx-0 relative inline-block'
                }>
                <HTMLEllipsis
                  id="ellipsis"
                  className={
                    isShowMore
                      ? 'max-w-[100%] lg:max-w-[351px] break-all text-textSecondary'
                      : 'max-w-[100%] lg:max-w-[351px] break-all text-textSecondary [&_span.LinesEllipsis-unit:nth-child(2)]:hidden'
                  }
                  unsafeHTML={`<div style="white-space: pre-wrap;">${collection.description.replace(
                    /\\n/g,
                    '<br/>',
                  )}</div>`}
                  maxLine={isShowMore ? 1000 : 2}
                  ellipsisHTML={'...<span class="px-[17px]"></span>&nbsp;&nbsp;'}
                  onReflow={({ clamped }) => {
                    setClamped(clamped)
                    return ''
                  }}
                  basedOn="words"
                />
                {(clamped || isShowMore) && (
                  <>
                    {isShowMore ? (
                      <div className="absolute right-0 bottom-[-23px]" id="ellipsis-show-more">
                        <button
                          className="inline text-sm leading-normal font-normal text-textPrimary"
                          onClick={() => setIsShowMore(false)}>
                          {t('common:common.cta-label-brief')}
                        </button>
                      </div>
                    ) : (
                      <div className="absolute right-0 bottom-0" id="ellipsis-show-more">
                        <button
                          className="inline text-sm leading-normal font-normal text-textPrimary"
                          onClick={() => setIsShowMore(true)}>
                          {t('common:common.cta-label-more')}
                        </button>
                      </div>
                    )}
                  </>
                )}
              </div>
            )}
            <div className="mb-8 mt-[25px] lg:mt-[36px] relative">
              <ul className="overflow-x-auto w-[100%] whitespace-nowrap scrollbar-hide relative">
                <li className="inline-block pl-4 lg:pl-0">
                  <div className="text-[16px] lg:text-[24px] font-extrabold text-textPrimary leading-[20px] lg:leading-[30px]">
                    {numberFormat(collection.itemCount ?? 0)}
                  </div>
                  <div className="text-sm leading-normal text-textSecondary">
                    {t('common:common.label-item')}
                  </div>
                </li>
                <li className="ml-[24px] lg:ml-[40px] inline-block">
                  <div className="text-[16px] lg:text-[24px] font-extrabold text-textPrimary leading-[20px] lg:leading-[30px]">
                    {numberFormat(collection.holderCount ?? 0)}
                  </div>
                  <div className="text-sm leading-normal text-textSecondary">
                    {t('common:common.label-owner')}
                  </div>
                </li>
                {collection.stats.map((stat, index) => (
                  <Fragment key={`collection-detail-info-stat-${index}`}>
                    <li className="ml-[24px] lg:ml-[40px] inline-block">
                      <PriceLabel
                        size={'base'}
                        paymentType={stat.paymentType}
                        price={stat.minPrice ?? undefined}
                      />
                      <div className="text-sm leading-normal text-textSecondary">
                        {stat.paymentType} {t('common:common.floor-price')}
                      </div>
                    </li>
                    <li className="ml-[24px] lg:ml-[40px] inline-block">
                      <PriceLabel
                        size={'base'}
                        paymentType={stat.paymentType}
                        price={stat.totalTradeAmount ?? undefined}
                      />
                      <div className="text-sm leading-normal text-textSecondary">
                        {stat.paymentType === 'ETH'
                          ? t('common:common.collection-eth-total-volume-extended')
                          : t('common:common.collection-matic-total-volume-extended')}
                      </div>
                    </li>
                  </Fragment>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CollectionDetailInfo
