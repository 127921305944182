import { ArrowsPointingOutIcon, ShareIcon } from '@heroicons/react/24/outline'
import { debounce } from 'lodash'
import { useCallback, useMemo } from 'react'
import CopyToClipboard from 'react-copy-to-clipboard'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

import { RefreshButton } from '@/shared/assets'
import { useItemsMetaReloadQuery } from '@/shared/services/items'

interface IItemDetailTopButtonGroupProps {
  collectionAddress: string
  tokenId: number
  handleOpenEnlarge: () => void
  itemId: string
}
function ItemDetailTopButtonGroup({
  collectionAddress,
  tokenId,
  handleOpenEnlarge,
  itemId,
}: IItemDetailTopButtonGroupProps) {
  const { t } = useTranslation(['common'])
  const origin = window.location.origin
  const url = `${origin}/item/${collectionAddress}/${tokenId}`
  const refreshToastId = 'meta-refresh'

  const { mutate } = useItemsMetaReloadQuery(itemId)
  const handleMetaDataRefresh = useCallback(() => {
    mutate()
    if (!toast.isActive(refreshToastId)) {
      toast(`${t('common:common.update-requested')}`, {
        toastId: refreshToastId,
      })
    }
  }, [mutate, t])
  const debouncedHandleMetaDataRefresh = useMemo(
    () => debounce(handleMetaDataRefresh, 500),
    [handleMetaDataRefresh],
  )

  return (
    <div className="hidden lg:flex justify-end gap-x-2 w-full mt-4">
      <RefreshButton onClick={debouncedHandleMetaDataRefresh} />
      <CopyToClipboard
        text={url}
        onCopy={() =>
          toast(t('common:common.toast-copied-wallet-address'), {
            toastId: 'share',
          })
        }>
        <button onClick={e => e.preventDefault()}>
          <ShareIcon className="w-6 h-6 text-textSecondary" />
        </button>
      </CopyToClipboard>
      <button onClick={handleOpenEnlarge}>
        <ArrowsPointingOutIcon className="w-6 h-6 text-iconSecondary" />
      </button>
    </div>
  )
}

export default ItemDetailTopButtonGroup
