import Image from 'next/image'
import CopyToClipboard from 'react-copy-to-clipboard'

import { ManagedDialog } from '@/shared/constants'
import { useDialog } from '@/shared/hooks'
import { shortenAddress } from '@/shared/utils'

interface IAccountInfoProps {
  accountAddress: string
  displayName?: string
}

const AccountInfo = ({ accountAddress }: IAccountInfoProps) => {
  const { setDialog } = useDialog()

  return (
    <div className="flex justify-between items-center">
      <div className="flex gap-2 items-center">
        <div className="p-5 rounded-full bg-bgPrimary">
          <Image width={24} height={24} src="/img/logo/logo.svg" alt="konkrit icon" />
        </div>

        <div className="flex flex-col gap-1">
          <p className="text-appTextTertiary font-medium text-sm leading-[18px]">내 지갑주소</p>
          <p className="text-lg font-medium text-textPrimary leading-[23px]">
            {shortenAddress(accountAddress)}
          </p>
        </div>
      </div>

      <CopyToClipboard
        text={accountAddress}
        onCopy={() => {
          setDialog(ManagedDialog.noticeCopyAddress)
        }}>
        <button type="button" className=" bg-appBgQuaternary p-3 rounded">
          <p className="text-xs font-medium leading-[15px]">복사</p>
        </button>
      </CopyToClipboard>
    </div>
  )
}

export default AccountInfo
