import { Space } from '@mantine/core'
import { memo, PropsWithChildren } from 'react'

import AccountInfo from './AccountInfo'
import DisconnectWalletButton from './DisconnectWalletButton'
import WalletButton from './WalletButton'
import WalletPopover from './WalletPopover'

interface IWalletWidgetProps {
  isLoggedIn: boolean
  accountAddress: string | null
  displayName?: string
  popoverPosition?: 'left' | 'right'
  handleDisconnectWallet: () => void
}

const WalletWidget = ({
  isLoggedIn,
  accountAddress,
  displayName,
  children,
  handleDisconnectWallet,
  popoverPosition = 'right',
}: PropsWithChildren<IWalletWidgetProps>) => {
  if (!isLoggedIn || accountAddress === null || displayName === null) return <WalletButton />
  return (
    <WalletPopover position={popoverPosition} displayName={displayName}>
      <AccountInfo accountAddress={accountAddress} displayName={displayName} />
      {children}
      <Space h={32} />
      <DisconnectWalletButton handleDisconnectWallet={handleDisconnectWallet} />
    </WalletPopover>
  )
}

export default memo(WalletWidget)
