import { useRouter } from 'next/router'
import { ReactNode, useCallback } from 'react'

import { ROUTE_PATH } from '@/shared/constants'
import { useAppDispatch, useAppSelector } from '@/shared/store/hooks'
import {
  disconnectWallet,
  selectCurrentAccountAddress,
  selectDisplayName,
  selectIsConnectedWallet,
  setIsRequestDisconnectWallet,
} from '@/shared/store/wallet'
import { shortenDisplayName } from '@/shared/utils'

import { Header } from '../gnb'

const HeaderLayout = ({ children }: { children: ReactNode }) => {
  const { push } = useRouter()

  const dispatch = useAppDispatch()
  const isConnectedWallet = useAppSelector(selectIsConnectedWallet)
  const currentAccountAddress = useAppSelector(selectCurrentAccountAddress)
  const storedDisplayNameData = useAppSelector(selectDisplayName)
  const displayName =
    storedDisplayNameData?.type === 'userName' || storedDisplayNameData?.type === 'ensName'
      ? shortenDisplayName(storedDisplayNameData.displayProfileName)
      : storedDisplayNameData?.displayProfileName

  const handleDisconnectWallet = useCallback(() => {
    dispatch(disconnectWallet())
    dispatch(setIsRequestDisconnectWallet(true))
  }, [dispatch])

  const handleSearchSubmit = useCallback(
    (searchInput: string) => {
      push({
        pathname: ROUTE_PATH.SEARCH_DETAIL,
        query: { query: searchInput },
      })
    },
    [push],
  )

  return (
    <>
      <Header
        isLoggedIn={isConnectedWallet}
        accountAddress={currentAccountAddress}
        displayName={displayName}
        handleDisconnectWallet={handleDisconnectWallet}
        handleSearchSubmit={handleSearchSubmit}
      />
      {children}
    </>
  )
}

export default HeaderLayout
