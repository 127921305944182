import { useRouter } from 'next/router'
import { ReactNode, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { ManagedDialog, ROUTE_PATH } from '@/shared/constants'
import { useAuthContext } from '@/shared/contexts'
import { useDialog } from '@/shared/hooks'
import { useGetCurrentAccountAdminableCollections } from '@/shared/services/accounts'
import { useAppSelector } from '@/shared/store/hooks'
import { selectCurrentAccountAddress } from '@/shared/store/wallet'
import { shortenAddress } from '@/shared/utils'

import { CopyAddressButton, TabBar } from '../common'

const CurrentAccountLayout = ({ children }: { children: ReactNode }) => {
  const { pathname } = useRouter()
  const { setDialog } = useDialog()
  const { isRequestingLoginCodeError } = useAuthContext()
  const { t } = useTranslation(['common', 'web-current-account'])
  const currentAccountAddress = useAppSelector(selectCurrentAccountAddress)

  const { data: accountCollections } = useGetCurrentAccountAdminableCollections({
    pathname: pathname,
    accountAddress: currentAccountAddress,
    params: {},
    refresh: false,
    isUseSuspense: false,
    isUseErrorBoundary: false,
  })

  const links = useMemo(() => {
    const menuLinks = [
      {
        to: ROUTE_PATH.CURRENT_ACCOUNT,
        detail: [
          {
            to: ROUTE_PATH.CURRENT_ACCOUNT,
            name: t('web-current-account:current-account.filter-label-exposed-nft'),
          },
          {
            to: ROUTE_PATH.CURRENT_ACCOUNT_HIDDEN,
            name: t('web-current-account:current-account.filter-label-hidden-nft'),
          },
        ],
        name: t('common:common.label-collected'),
      },
      { to: ROUTE_PATH.CURRENT_ACCOUNT_LIKED, name: t('common:common.label-like') },
      {
        to: ROUTE_PATH.CURRENT_ACCOUNT_ACTIVITY,
        detail: [
          {
            to: ROUTE_PATH.CURRENT_ACCOUNT_ACTIVITY,
            name: t('web-current-account:current-account.sale-purchase-transfer-histories'),
          },
          {
            to: ROUTE_PATH.CURRENT_ACCOUNT_RECEIVED_OFFER,
            name: t('common:common.label-offer-received-history'),
          },
          {
            to: ROUTE_PATH.CURRENT_ACCOUNT_SENT_OFFER,
            name: t('common:common.label-offer-made-history'),
          },
        ],
        name: t('common:common.label-activity'),
      },
    ]

    if (accountCollections !== undefined && accountCollections?.collections.length > 0) {
      menuLinks.push({
        to: ROUTE_PATH.CURRENT_ACCOUNT_COLLECTION,
        name: t('common:common.label-collection'),
      })
    }

    return menuLinks
  }, [accountCollections, t])

  useEffect(() => {
    if (isRequestingLoginCodeError) {
      setDialog(ManagedDialog.disconnectedWallet)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRequestingLoginCodeError])

  return (
    <div>
      {currentAccountAddress ? (
        <div className="flex items-center gap-1 my-10 ml-4">
          <h1 className="text-2xl font-semibold">{shortenAddress(currentAccountAddress)}</h1>
          <CopyAddressButton accountAddress={currentAccountAddress} isOnlyButton />
        </div>
      ) : (
        <div className="my-10 ml-4 w-[164px] h-[32px] bg-appBgQuaternary rounded-sm" />
      )}

      <TabBar theme="links" links={links} pathname={pathname} />
      {children}
    </div>
  )
}

export default CurrentAccountLayout
