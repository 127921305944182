import { getPayAmountsByPrice } from '@modernlion/marketplace-registry'
import { convertPriceToBigNumber, ZERO_ADDRESS } from '@modernlion/solidity-registry'
import { ItemType } from '@opensea/seaport-js/lib/constants'
import { CreateOrderInput, OrderWithCounter } from '@opensea/seaport-js/lib/types'
import dayjs from 'dayjs'

import { ICreateListingFormData } from '@/shared/types'
import { createOrder } from '@/shared/utils'

import { AppThunk } from '..'
import {
  cancelAllListing,
  getListingItemInfo,
  registerToSellForItem,
  resetCancelState,
  resetListingState,
  resetSignatureState,
  setCancelFailedForUserDenied,
  setCancelIsError,
  setCancelIsRequired,
  setCounterForListing,
  setIsCreateListingError,
  setIsSigning,
  setListingAction,
  setListingFormData,
  setOrderForListing,
  setOrderHashForListing,
  setSignature,
  setSignIsError,
} from '../trades'

/**
 * 판매 등록(listing)에 관련된 state 값들 제거
 */
export const resetListingStates = (): AppThunk => async dispatch => {
  dispatch(resetCancelState())
  dispatch(resetSignatureState())
  dispatch(resetListingState())
}

/**
 * 판매 등록(listing) 다이알로그 열기
 *
 * 1. 메타마스크가 잠긴 상태인지 확인
 * 2. listing 관련 state 초기화
 * 3. 메타마스크가 잠겼다면 메타마스크가 잠겼다는 다이알로그 열기
 * 4. 정보 가져오기 전 loading 화면 보여주기
 * 5. 판매 "등록" 액션 세팅 (판매는 등록일 수도 수정일 수도 있음)
 * 6. 컬렉션 권한 허용 했는지 확인
 * 7. 판매하려는 아이템 정보 세팅
 * 8. 정보 가져온 후 loading 화면 제거
 * 9. 판매 등록 다이알로그 최종적으로 열기
 */
export const listingDialogOpen =
  ({
    itemId,
    collectionTitle,
    collectionAddress,
    tokenId,
    itemTitle,
    mediaUrl,
    ownerAddress,
    chainId,
    handlePreparingToOpenDialog,
    handleListingFormDialog,
  }: {
    itemId: string
    collectionTitle: string
    collectionAddress: string
    tokenId: string
    itemTitle: string
    mediaUrl: string
    ownerAddress: string
    chainId: string
    handlePreparingToOpenDialog: (isPreparingToOpenDialog: boolean) => void
    handleListingFormDialog: (listingFormDialogIsOpen: boolean) => void
  }): AppThunk =>
  async dispatch => {
    dispatch(resetListingStates())

    handlePreparingToOpenDialog(true)
    dispatch(setListingAction('create'))
    await dispatch(
      getListingItemInfo({
        itemId,
        collectionTitle,
        collectionAddress,
        tokenId,
        itemTitle,
        mediaUrl,
        ownerAddress,
        chainId,
      }),
    )
    handlePreparingToOpenDialog(false)
    handleListingFormDialog(true)
  }

export const submitListingFormData =
  (createListingData: ICreateListingFormData, isCancelRequired: boolean): AppThunk =>
  async dispatch => {
    dispatch(setListingFormData(createListingData))
    dispatch(setCancelIsRequired(isCancelRequired))
  }

export const signForSellOrder = (): AppThunk => async (dispatch, getState) => {
  const currentAccountAddress = getState().wallet.currentProfile?.accountAddress ?? null
  const listingFormData = getState().listing.listingFormData
  const listingItemInfo = getState().listing.listingItemInfo
  const tradeAddress = getState().contractAddress.tradeAddress

  if (tradeAddress === null) {
    return dispatch(setIsCreateListingError('No trade addresses'))
  }

  if (currentAccountAddress === null) {
    return dispatch(setSignIsError('No current account address'))
  }

  if (listingFormData === null) {
    return dispatch(setSignIsError('No listing form data'))
  }

  if (listingItemInfo === null) {
    return dispatch(setSignIsError('No listing item info'))
  }

  if (listingItemInfo.tradeAddress === null) {
    return dispatch(setSignIsError('No trade address'))
  }

  if (listingItemInfo.royaltyInfo === null) {
    return dispatch(setSignIsError('No royalty info'))
  }

  const { marketFee, royalties, royaltyReceivers, marketOwner } = listingItemInfo.royaltyInfo
  const { paymentType, expirationTime, price } = listingFormData

  const { priceExcludingRoyalties, marketRoyalty, collectionRoyalties } = getPayAmountsByPrice(
    price,
    marketFee,
    royalties,
  )

  const resourceForCreateOrder: CreateOrderInput = {
    startTime: String(dayjs().unix()),
    endTime: String(dayjs(expirationTime).unix()),
    offer: [
      {
        itemType: ItemType.ERC721,
        token: listingItemInfo.collectionAddress,
        identifier: String(listingItemInfo.tokenId),
      },
    ],
    consideration: [
      {
        token: ZERO_ADDRESS,
        amount: convertPriceToBigNumber(priceExcludingRoyalties, paymentType).toString(),
        recipient: currentAccountAddress,
      },
      {
        token: ZERO_ADDRESS,
        amount: convertPriceToBigNumber(marketRoyalty, paymentType).toString(),
        recipient: marketOwner,
      },
      ...royaltyReceivers.map((receiver, index) => ({
        token: ZERO_ADDRESS,
        amount: convertPriceToBigNumber(collectionRoyalties[index], paymentType).toString(),
        recipient: receiver,
      })),
    ],
    zone: tradeAddress.zone,
  }

  await createOrder(
    listingItemInfo.chainId,
    resourceForCreateOrder,
    currentAccountAddress,
    tradeAddress,
    (order: OrderWithCounter) => dispatch(setOrderForListing(order)),
    (orderHash: string) => dispatch(setOrderHashForListing(orderHash)),
    (counter: number) => dispatch(setCounterForListing(counter)),
    (signature: string) => dispatch(setSignature(signature)),
    (loading: boolean) => dispatch(setIsSigning(loading)),
    (error: string) => dispatch(setSignIsError(error)),
  )
}

export const makeListing = (): AppThunk => async (dispatch, getState) => {
  const signature = getState().signature.signature
  const order = getState().listing.order
  const orderHash = getState().listing.orderHash
  const counter = getState().listing.counter
  const listingItemInfo = getState().listing.listingItemInfo
  const listingFormData = getState().listing.listingFormData

  if (signature === null) {
    return dispatch(setIsCreateListingError('No signature'))
  }

  if (order === null) {
    return dispatch(setIsCreateListingError('No order'))
  }

  if (orderHash === null) {
    return dispatch(setIsCreateListingError('No order hash'))
  }

  if (counter === null) {
    return dispatch(setIsCreateListingError('No counter'))
  }

  if (listingItemInfo === null) {
    return dispatch(setIsCreateListingError('No listing item info'))
  }

  if (listingFormData === null) {
    return dispatch(setIsCreateListingError('No listing form data'))
  }

  const { collectionAddress, itemId } = listingItemInfo

  const { royaltyInfo } = listingItemInfo

  if (royaltyInfo === null) {
    return dispatch(setIsCreateListingError('No royalty info'))
  }

  await dispatch(
    registerToSellForItem({
      itemId,
      collectionAddress,
      listingFormData,
      order,
      orderHash,
      counter,
      royaltyInfo,
      signature,
    }),
  )
}

export const retry =
  ({
    handleWalletWaitingForListingDialog,
    handleWalletWaitingForListingCancelDialog,
  }: {
    handleWalletWaitingForListingDialog: (listingSigningDialogIsOpen: boolean) => void
    handleWalletWaitingForListingCancelDialog: (
      walletWaitingForListingCancelDialogIsOpen: boolean,
    ) => void
  }): AppThunk =>
  async (dispatch, getState) => {
    const listingAction = getState().listing.action

    if (listingAction === 'create' || listingAction === 'edit') {
      dispatch(setCancelIsError(null))
      dispatch(setSignIsError(null))
      dispatch(setCancelFailedForUserDenied(false))
      dispatch(setIsCreateListingError(null))

      dispatch(setSignature(null))

      handleWalletWaitingForListingDialog(true)
    } else if (listingAction === 'cancel') {
      dispatch(setCancelIsError(null))
      dispatch(setCancelFailedForUserDenied(false))
      dispatch(requestCancelAllListing(handleWalletWaitingForListingCancelDialog))
    }
  }

export const listingEditOrCancelDialogOpen =
  ({
    itemId,
    collectionTitle,
    collectionAddress,
    tokenId,
    itemTitle,
    mediaUrl,
    ownerAddress,
    chainId,
    handlePreparingToOpenDialog,
    handleListingEditOrCancelDialogOpen,
  }: {
    itemId: string
    collectionTitle: string
    collectionAddress: string
    tokenId: string
    itemTitle: string
    mediaUrl: string
    ownerAddress: string
    chainId: string
    handlePreparingToOpenDialog: (isPreparingToOpenDialog: boolean) => void
    handleListingEditOrCancelDialogOpen: (listingEditOrCancelDialogIsOpen: boolean) => void
  }): AppThunk =>
  async dispatch => {
    dispatch(resetListingStates())

    handlePreparingToOpenDialog(true)
    await dispatch(
      getListingItemInfo({
        itemId,
        collectionTitle,
        collectionAddress,
        tokenId,
        itemTitle,
        mediaUrl,
        ownerAddress,
        chainId,
      }),
    )
    handlePreparingToOpenDialog(false)
    handleListingEditOrCancelDialogOpen(true)
  }

export const requestCancelAllListing =
  (
    handleWalletWaitingForListingCancelDialog: (
      walletWaitingForListingCancelDialogIsOpen: boolean,
    ) => void,
  ): AppThunk =>
  async dispatch => {
    dispatch(cancelAllListing())
    handleWalletWaitingForListingCancelDialog(true)
  }
