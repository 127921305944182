import { Space } from '@mantine/core'
import bigDecimal from 'js-big-decimal'
import Image from 'next/image'
import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { ManagedDialog } from '@/shared/constants'
import { useDialog } from '@/shared/hooks'
import { IAllCoinPrice, ICoinPrice } from '@/shared/types'
import { getPaymentIconPath } from '@/shared/utils'
import {
  convertBalanceToCurrency,
  makePriceForRender,
} from '@/shared/utils/convertToCurrencyCoinPrice'
import { numberFormat } from '@/shared/utils/formatter'

interface IBalanceItem {
  formattedBalance: string | number
  convertedPrice: string
  currency: { icon: string; name: string }
  iconPath: string
  tokenSymbol: string
}

function BalanceItem({
  formattedBalance,
  convertedPrice,
  currency,
  iconPath,
  tokenSymbol,
}: IBalanceItem) {
  return (
    <div className="flex justify-between items-center">
      <div className="flex gap-2 items-center">
        <div className="w-4 h-4 flex justify-center">
          <Image width={10} height={16} src={iconPath} alt="ether icon" />
        </div>
        <p>
          <span>{formattedBalance}</span>
          &nbsp;
          <span>{tokenSymbol}</span>
        </p>
      </div>
      <p className="text-sm leading-normal font-normal flex text-textSecondary">
        {makePriceForRender({
          price: convertedPrice,
          currency,
        })}
      </p>
    </div>
  )
}

interface IWalletBalanceProps {
  currency: { icon: string; name: string }
  coinPrice?: ICoinPrice
  polBalance: string | null
  wpolBalance: string | null
  ethBalance: string | null
  wethBalance: string | null
  allCoinPrice?: IAllCoinPrice
  coinPriceStatus: 'loading' | 'success' | 'error'
}

function WalletBalance({
  currency,
  ethBalance,
  wethBalance,
  polBalance,
  wpolBalance,
  allCoinPrice,
  coinPriceStatus,
}: IWalletBalanceProps) {
  const { t } = useTranslation(['common', 'web-header'])

  const { setDialog } = useDialog()

  const getBalanceInfo = useCallback(
    (balance: string | null, currency: { icon: string; name: string }, coinPrice?: ICoinPrice) => {
      if (coinPrice === undefined) {
        return {
          formattedBalance: '0',
          convertedPrice: '0',
        }
      }
      return {
        formattedBalance: numberFormat(Number(balance)),
        convertedPrice: convertBalanceToCurrency({
          price: balance ?? '0',
          coinCurrency: coinPrice,
          currencyName: currency.name,
        }),
      }
    },
    [],
  )

  const polBalanceInfo = useMemo(
    () => getBalanceInfo(polBalance, currency, allCoinPrice?.POL),
    [allCoinPrice?.POL, currency, getBalanceInfo, polBalance],
  )
  const wpolBalanceInfo = useMemo(
    () => getBalanceInfo(wpolBalance, currency, allCoinPrice?.WPOL),
    [allCoinPrice?.WPOL, currency, getBalanceInfo, wpolBalance],
  )
  const ethBalanceInfo = useMemo(
    () => getBalanceInfo(ethBalance, currency, allCoinPrice?.ETH),
    [allCoinPrice?.ETH, currency, ethBalance, getBalanceInfo],
  )
  const wethBalanceInfo = useMemo(
    () => getBalanceInfo(wethBalance, currency, allCoinPrice?.WETH),
    [allCoinPrice?.WETH, currency, getBalanceInfo, wethBalance],
  )

  const totalBalance = useMemo(() => {
    if (allCoinPrice === undefined) return '0'

    const totalPolBalance = bigDecimal.add(
      polBalanceInfo.convertedPrice,
      wpolBalanceInfo.convertedPrice,
    )
    const totalEtherBalance = bigDecimal.add(
      ethBalanceInfo.convertedPrice,
      wethBalanceInfo.convertedPrice,
    )

    return makePriceForRender({
      price: bigDecimal.add(totalEtherBalance, totalPolBalance),
      currency,
    })
  }, [
    allCoinPrice,
    currency,
    ethBalanceInfo.convertedPrice,
    polBalanceInfo.convertedPrice,
    wethBalanceInfo.convertedPrice,
    wpolBalanceInfo.convertedPrice,
  ])

  const handleSwapDialogOpen = useCallback(() => {
    setDialog(ManagedDialog.swapChainSelect)
  }, [setDialog])

  if (coinPriceStatus === 'loading') {
    return <WalletBalance.Skeleton />
  }

  if (coinPriceStatus === 'error') return null

  return (
    <div className="p-4 bg-appBgTertiary rounded-lg">
      <div className="flex justify-between border-b border-b-appBgSecondary pb-4">
        <p>{t('common:common.label-tokens')}</p>
        <p className="text-base leading-normal font-semibold flex">{totalBalance}</p>
      </div>
      <Space h={12} />
      <BalanceItem
        {...ethBalanceInfo}
        currency={currency}
        iconPath={getPaymentIconPath('ETH')}
        tokenSymbol="ETH"
      />
      <Space h={12} />
      <BalanceItem
        {...wethBalanceInfo}
        currency={currency}
        iconPath={getPaymentIconPath('WETH')}
        tokenSymbol="WETH"
      />
      <Space h={12} />
      <BalanceItem
        {...polBalanceInfo}
        currency={currency}
        iconPath={getPaymentIconPath('POL')}
        tokenSymbol="POL"
      />
      <Space h={12} />
      <BalanceItem
        {...wpolBalanceInfo}
        currency={currency}
        iconPath={getPaymentIconPath('WPOL')}
        tokenSymbol="WPOL"
      />
      <Space h={20} />
      <button
        type="button"
        className="text-center py-[13px] w-full bg-appBgQuaternary rounded-sm"
        onClick={handleSwapDialogOpen}>
        <p className="text-base font-medium leading-6">{t('common:common.label-swap')}</p>
      </button>
      <Space h={8} />
    </div>
  )
}
WalletBalance.Skeleton = function WalletBalanceSkeleton() {
  const { t } = useTranslation(['common', 'web-header'])

  return (
    <div className="p-4 bg-appBgTertiary rounded-lg h-64 flex flex-col">
      <div className="flex justify-between border-b border-b-appBgSecondary pb-4">
        <p>{t('common:common.label-tokens')}</p>
        <div className="w-[150px] h-6 bg-appBgQuaternary rounded-[2px]" />
      </div>

      <Space h={12} />

      <div className="flex items-center justify-between">
        <div className="flex gap-2 items-center">
          <div className="w-4 h-4 flex justify-center">
            <Image width={10} height={16} src="/img/icon/ethereum.svg" alt="ether icon" />
          </div>
          <div className="w-[50px] h-6 bg-appBgQuaternary rounded-[2px]" />
        </div>

        <div className="w-[100px] h-[21px] bg-appBgQuaternary rounded-[2px]" />
      </div>

      <Space h={12} />

      <div className="flex items-center justify-between">
        <div className="flex gap-2 items-center">
          <div className="w-4 h-4 flex justify-center">
            <Image width={10} height={16} src="/img/icon/wrapped-ethereum.svg" alt="ether icon" />
          </div>
          <div className="w-[50px] h-6 bg-appBgQuaternary rounded-[2px]" />
        </div>

        <div className="w-[100px] h-[21px] bg-appBgQuaternary rounded-[2px]" />
      </div>

      <Space h={12} />

      <div className="flex items-center justify-between">
        <div className="flex gap-2 items-center">
          <div className="w-4 h-4 flex justify-center">
            <Image width={16} height={16} src="/img/icon/matic.svg" alt="ether icon" />
          </div>

          <div className="w-[50px] h-6 bg-appBgQuaternary rounded-[2px]" />
        </div>

        <div className="w-[100px] h-[21px] bg-appBgQuaternary rounded-[2px]" />
      </div>

      <Space h={12} />

      <div className="flex items-center justify-between">
        <div className="flex gap-2">
          <Image width={16} height={16} src="/img/icon/wmatic.svg" alt="ether icon" />
          <div className="w-[50px] h-6 bg-appBgQuaternary rounded-[2px]" />
        </div>

        <div className="w-[100px] h-[21px] bg-appBgQuaternary rounded-[2px]" />
      </div>
      <Space h={20} />

      <button type="button" className="text-center py-[13px] w-full bg-appBgQuaternary rounded-sm">
        <p className="text-base font-medium leading-6">{t('common:common.label-swap')}</p>
      </button>
    </div>
  )
}
export default WalletBalance
