import dayjs from 'dayjs'
import { default as BigDecimal, default as bigDecimal } from 'js-big-decimal'
import Image from 'next/image'
import { useRouter } from 'next/router'
import { i18n } from 'next-i18next'
import { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { BenefitStatusLabel } from '@/components/common'
import { useCollectionItemDetailQuery } from '@/shared/services/collections'
import { useGetItemUtilities, useMarketsInfoQuery } from '@/shared/services/market'
import { cx, getChainscanUrl, shortenAddress } from '@/shared/utils'

import ItemSectionCard from './ItemSectionCard'

interface IItemDetailDescriptionProps {
  className?: string
}
function ItemDetailDescription({ className }: IItemDetailDescriptionProps) {
  const { query, pathname } = useRouter()
  const language = i18n?.language ?? 'ko'
  const { tokenId, collectionAddress } = query as { tokenId?: string; collectionAddress?: string }
  const { t } = useTranslation(['common', 'web-view-item'])

  const [claimBenefitsDetailIsOpen, setClaimBenefitsDetailIsOpen] = useState(false)

  const {
    data: marketsInfoData,
    status: marketsInfoStatus,
    refetch: marketsInfoDataRefetch,
  } = useMarketsInfoQuery(pathname)

  const {
    data: itemDetailData,
    status: itemDetailStatus,
    refetch: itemDetailDataRefetch,
  } = useCollectionItemDetailQuery(pathname, {
    collectionAddress,
    tokenId,
  })

  const itemIds = [`${collectionAddress}-${tokenId}`]
  const { data: benefitData, status: claimUtilitiesStatus } = useGetItemUtilities({
    itemIds,
    hasBenefits: itemDetailData?.collection.hasBenefits,
  })
  const claimUtilitiesData = useMemo(() => {
    return (
      (benefitData &&
        benefitData.benefits &&
        benefitData.benefits.length > 0 &&
        benefitData.benefits[0].benefitItems) ||
      []
    )
  }, [benefitData])

  const totalCreatorRoyalties = useMemo(
    () =>
      itemDetailData?.collection.collectionRoyalties.reduce((acc, curr) => {
        return bigDecimal.add(acc, curr.rate)
      }, '0'),
    [itemDetailData?.collection.collectionRoyalties],
  )

  const MarketFeeInfo = useCallback(() => {
    const marketFeeInfoForChain = marketsInfoData?.market?.find(
      market => market.chainId === itemDetailData?.collection.chainId,
    )
    if (marketFeeInfoForChain === undefined) return null
    return (
      <div className="text-sm text-textPrimary">{`${Number(
        bigDecimal.multiply(marketFeeInfoForChain.feeRate, '100'),
      ).toFixed(1)}%`}</div>
    )
  }, [itemDetailData?.collection.chainId, marketsInfoData?.market])

  const ClaimBenefitsItem = useCallback(
    ({
      benefitTitle,
      benefitStatus,
      benefitEndAt,
      isLastItem,
    }: {
      benefitTitle: string
      benefitStatus: boolean
      benefitEndAt: number
      isLastItem: boolean
    }) => {
      return (
        <div
          className={cx(
            'flex flex-col px-2 py-4',
            !isLastItem && 'border-b border-borderQuarternary',
          )}>
          <div className="w-full flex gap-x-2 justify-between items-center mb-1">
            <div className="flex items-center gap-x-1">
              <div className="shrink-0">
                <Image
                  width={20}
                  height={20}
                  src="/img/icon/special-benefit.svg"
                  alt="benefit icon"
                />
              </div>
              <p className="text-base font-medium text-textPrimary truncate">{benefitTitle}</p>
            </div>

            <BenefitStatusLabel status={benefitStatus} />
          </div>

          <p className="text-sm font-medium">
            {t('common:common.claim-status-expiring-on-date').replace(
              '{YYYY.MM.DD}',
              `${
                language === 'ko'
                  ? dayjs(benefitEndAt).format('YYYY년 MM월 DD일')
                  : dayjs(benefitEndAt).format('YYYY.MM.DD')
              }`,
            )}
          </p>
        </div>
      )
    },
    [language, t],
  )

  const ClaimBenefitsUsage = useCallback(() => {
    const renderBenefits = benefitData?.benefits[0]?.benefitDescription?.split('\n')

    if (
      !renderBenefits ||
      claimUtilitiesData === null ||
      claimUtilitiesData.length === 0 ||
      claimUtilitiesStatus !== 'success' ||
      !itemDetailData?.collection.hasBenefits
    ) {
      return null
    }

    return (
      <>
        <div className="text-sm font-semibold lg:text-base lg:font-medium">
          {t('web-view-item:view-item.benefit-usage-status-title')}
        </div>

        <div className="p-2 pt-0 mt-[12px] bg-bgTertiary rounded-[12px] flex flex-col text-textSecondary mb-8">
          {claimUtilitiesData.map(({ benefitStatus, benefitTitle, benefitEndAt }, index) => (
            <ClaimBenefitsItem
              key={`claim-benefits-item-${index}`}
              benefitStatus={benefitStatus}
              benefitTitle={benefitTitle}
              benefitEndAt={benefitEndAt}
              isLastItem={index + 1 === claimUtilitiesData.length}
            />
          ))}
          <div
            className="bg-bgQuarternary w-full p-[10px] rounded-xl cursor-pointer"
            onClick={() => setClaimBenefitsDetailIsOpen(!claimBenefitsDetailIsOpen)}>
            <div className="flex justify-between items-center max-h-[20px]">
              <div className="flex items-center gap-1">
                <Image width={16} height={16} src="/img/icon/hc-info.svg" alt="info icon" />
                <div className="text-sm text-textPrimary font-medium">
                  {t('common:common.claim-learn-more-about-benefits')}
                </div>
              </div>

              <Image
                width={20}
                height={20}
                src={
                  claimBenefitsDetailIsOpen
                    ? '/img/icon/hc-arrowb-up.svg'
                    : '/img/icon/hc-arrowb-down.svg'
                }
                alt={claimBenefitsDetailIsOpen ? 'arrow up icon' : 'arrow down icon'}
              />
            </div>

            {claimBenefitsDetailIsOpen && (
              <div className="px-1 border-t-[1px] border-r-0 border-l-0 border-b-0 border-t-white/[.08] pt-[10px] mt-2">
                {renderBenefits?.map((detailBenefit, index) => (
                  <p className="text-sm" key={`detailBenefit-item-${index}`}>
                    {detailBenefit}
                  </p>
                ))}
              </div>
            )}
          </div>
        </div>
      </>
    )
  }, [
    benefitData?.benefits,
    claimUtilitiesData,
    claimUtilitiesStatus,
    itemDetailData?.collection.hasBenefits,
    t,
    claimBenefitsDetailIsOpen,
    ClaimBenefitsItem,
  ])

  return (
    <ItemSectionCard className={cx(className, 'flex flex-col')}>
      {itemDetailData && marketsInfoStatus === 'success' && itemDetailStatus === 'success' && (
        <>
          <div className="pb-[8px]">
            <ClaimBenefitsUsage />
            <div className="text-sm font-semibold lg:text-base lg:font-medium">
              {t('common:common.item-creator')}
            </div>
            <div className="p-[16px] lg:py-[24px] mt-[12px] bg-bgTertiary rounded-[12px] flex flex-col gap-y-[16px] text-textSecondary">
              <div className="grid grid-cols-1 lg:grid-cols-2 gap-y-[16px] gap-x-[32px]">
                <div className="flex justify-between">
                  <div className="text-sm">
                    {t('common:common.item-detail-contents-detail-information-contract-address')}
                  </div>
                  <div className="text-sm text-textAccent">
                    <a
                      onClick={() => {
                        if (window !== undefined)
                          window.location.href = `${getChainscanUrl(
                            itemDetailData.collection.chainId,
                          )}/address/${collectionAddress}`
                      }}
                      rel="noopener noreferrer">
                      {shortenAddress(collectionAddress || '')}
                    </a>
                  </div>
                </div>
                <div className="flex justify-between">
                  <div className="text-sm"> {t('common:common.item-creator')}</div>
                  {itemDetailData.collection.accountAddress && (
                    <div className="text-sm text-textAccent">
                      <a
                        onClick={() => {
                          if (window !== undefined)
                            window.location.href = `${getChainscanUrl(
                              itemDetailData.collection.chainId,
                            )}/address/${collectionAddress}`
                        }}
                        rel="noopener noreferrer">
                        {shortenAddress(itemDetailData.collection.accountAddress || '')}
                      </a>
                    </div>
                  )}
                </div>
              </div>
              <div className="grid grid-cols-1 lg:grid-cols-2 gap-y-[16px] gap-x-[32px]">
                <div className="flex justify-between">
                  <div className="text-sm">{t('common:common.item-creator-fee')}</div>
                  <div className="text-sm text-textPrimary">{`${
                    totalCreatorRoyalties !== '0'
                      ? Number(BigDecimal.multiply(totalCreatorRoyalties, '100')).toFixed(1)
                      : totalCreatorRoyalties
                  }%`}</div>
                </div>
                <div className="flex justify-between">
                  <div className="text-sm">{t('common:common.item-market-fee')}</div>
                  <MarketFeeInfo />
                </div>
              </div>
            </div>
          </div>
          {itemDetailData.properties.length !== 0 && (
            <div className="mt-5">
              <div className="text-sm font-semibold lg:text-base lg:font-medium">
                {t('common:common.label-property')}
              </div>
              <div className="mt-[12px] flex flex-wrap gap-[8px] lg:gap-[12px]">
                {itemDetailData.properties?.map((property, index: number) => (
                  <div
                    key={`offer-info-${index}`}
                    className="py-[12px] px-[16px] bg-bgTertiary rounded-[12px] min-w-[97px] lg:min-w-[107px]">
                    <div className="leading-[18px] lg:leading-[21px] text-xs lg:text-sm text-textSecondary">
                      {property.title}
                    </div>
                    <div className="leading-[21px] lg:leading-[24px] text-sm lg:text-base mt-[4px] font-medium">
                      {property.value}
                    </div>
                    <div className="leading-[18px] lg:leading-[18px] text-xs text-textSecondary">
                      {`${property.rarityRate}%`}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
        </>
      )}
      {(itemDetailStatus === 'error' || marketsInfoStatus === 'error') && (
        <div className="mt-[76px] flex flex-col gap-3 items-center justify-center text-lg text-textSecondary">
          <div className="">{t('common:error-data')}</div>
          <button
            className="py-[10px] px-3 bg-bgQuarternary rounded-sm text-sm text-textPrimary"
            onClick={() => {
              marketsInfoDataRefetch()
              itemDetailDataRefetch()
            }}>
            {t('common:common.cta-label-try-again')}
          </button>
        </div>
      )}
    </ItemSectionCard>
  )
}

export default ItemDetailDescription
