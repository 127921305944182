import { Space } from '@mantine/core'
import Image from 'next/image'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { Fragment, useCallback, useMemo, useRef } from 'react'
import { useTranslation } from 'react-i18next'

import { ITEM_DETAIL_TAB, ManagedDialog, ROUTE_PATH } from '@/shared/constants'
import {
  useBalance,
  useDialog,
  useGetSnapshotInError,
  useIntersectionObserver,
} from '@/shared/hooks'
import { useCollectionItemDetailQuery, useGetItemDetailOffers } from '@/shared/services/collections'
import { getOfferItem } from '@/shared/services/itemOrder'
import { useAppDispatch, useAppSelector } from '@/shared/store/hooks'
import { acceptDialogOpen } from '@/shared/store/thunks/thunksForAccept'
import { selectCurrentAccountAddress } from '@/shared/store/wallet'
import { CustomError, IOfferItemForDetailPage, OfferPaymentType } from '@/shared/types'
import { convertBalanceToCurrencyWithSymbol, shortenAddress } from '@/shared/utils'
import { differentDateTime } from '@/shared/utils/timeFormatter'

import { PriceLabel } from '../common'
import { Spinner } from '../core-ui'
import ItemSectionCard from './ItemSectionCard'

function HighOffer({
  price,
  offerAccountAddress,
  expiredAt,
  itemOwnerAccountAddress,
  accountAddress,
  priceConvertToExchangeRate,
  offerType,
  handleAcceptOffer,
}: {
  price: number
  offerAccountAddress?: string
  expiredAt: string
  itemOwnerAccountAddress: string
  accountAddress: string | null
  offerType: OfferPaymentType
  priceConvertToExchangeRate: (price: number, offerType: OfferPaymentType) => string
  handleAcceptOffer: () => void
}) {
  const { t } = useTranslation(['common', 'web-view-item'])
  const endDate = differentDateTime({ itemDateTime: expiredAt, t })

  const exchangedPrice = priceConvertToExchangeRate(price, offerType)

  if (offerAccountAddress === undefined) return null

  return (
    <div className="py-[20px] px-[16px] bg-bgTertiary rounded-[12px] flex items-center justify-between">
      <div>
        <div>{t('common:common.label-best-offer-price')}</div>
        <Space h={8} />
        <div className="flex items-center gap-x-1">
          <PriceLabel size="base" paymentType={offerType} price={Number(price)} />
          {exchangedPrice && (
            <p className="text-sm text-appTextTertiary font-medium">({exchangedPrice})</p>
          )}
        </div>
        <div className="text-textSecondary text-sm">
          From{' '}
          <Link
            href={ROUTE_PATH.ACCOUNT.replace(':accountAddress', offerAccountAddress)}
            className="text-textPrimary">
            {shortenAddress(offerAccountAddress.toUpperCase())}
          </Link>{' '}
          ･ {endDate}
        </div>
      </div>
      {itemOwnerAccountAddress.toLowerCase() === accountAddress?.toLowerCase() && (
        <button
          className="px-[12px] py-[8px] rounded-[4px] bg-gray-50 text-black"
          onClick={handleAcceptOffer}>
          {t('common:common.cta-label-accept')}
        </button>
      )}
    </div>
  )
}

function Offers({
  index,
  price,
  offerAccountAddress,
  expiredAt,
  itemOwnerAccountAddress,
  accountAddress,
  priceConvertToExchangeRate,
  offerType,
  handleAcceptOffer,
}: {
  index: number
  price: number
  offerAccountAddress: string
  expiredAt: string
  itemOwnerAccountAddress: string
  accountAddress: string | null
  priceConvertToExchangeRate: (price: number, offerType: OfferPaymentType) => string
  offerType: OfferPaymentType
  handleAcceptOffer: () => void
}) {
  const { t } = useTranslation(['common', 'web-view-item'])
  const endDate = differentDateTime({ itemDateTime: expiredAt, t })

  const exchangedPrice = priceConvertToExchangeRate(price, offerType)

  if (offerAccountAddress === undefined) return null

  return (
    <div
      key={`detail-offer-${index}`}
      className="p-4 flex items-center justify-between border-b-bgQuarternary border-b">
      <div>
        <Space h={8} />
        <div className="flex items-center gap-x-1">
          <PriceLabel size="base" paymentType={offerType} price={Number(price)} />
          {exchangedPrice && (
            <p className="text-sm text-appTextTertiary font-medium">({exchangedPrice})</p>
          )}
        </div>
        <div className="text-textSecondary text-sm">
          From{' '}
          <Link
            href={ROUTE_PATH.ACCOUNT.replace(':accountAddress', offerAccountAddress)}
            className="text-textPrimary">
            {shortenAddress(offerAccountAddress.toUpperCase())}
          </Link>{' '}
          ･ {endDate}
        </div>
      </div>
      {itemOwnerAccountAddress.toLowerCase() === accountAddress?.toLowerCase() && (
        <button
          className="px-[12px] py-[8px] rounded-[4px] bg-bgQuarternary"
          onClick={handleAcceptOffer}>
          {t('common:common.cta-label-accept')}
        </button>
      )}
    </div>
  )
}

function ItemDetailOffer({ className }: { className?: string }) {
  const { pathname, query } = useRouter()
  const dispatch = useAppDispatch()
  const { getCurrentSnapshotInError } = useGetSnapshotInError()

  const targetRef = useRef<HTMLDivElement>(null)
  const { collectionAddress, tokenId } = query as { collectionAddress?: string; tokenId?: string }
  const { handleSpecificDialog } = useDialog()

  const { allCoinPrice: coinPrice, currency } = useBalance()
  const currentAccountAddress = useAppSelector(selectCurrentAccountAddress)
  const { t } = useTranslation(['common', 'web-view-item'])

  const handleAcceptSummaryDialog = handleSpecificDialog(ManagedDialog.acceptSummary)

  const { data: itemDetailData, status: itemDetailDataStatus } = useCollectionItemDetailQuery(
    pathname,
    {
      collectionAddress,
      tokenId,
    },
  )

  const {
    data: offerDataList,
    status: offerDataStatus,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
    refetch: offerDataListRefetch,
    error,
  } = useGetItemDetailOffers(pathname, collectionAddress, tokenId)

  useIntersectionObserver({
    onIntersect: () => {
      !isFetchingNextPage && hasNextPage && fetchNextPage()
    },
    targetRef,
  })

  const itemOwnerAccountAddress = useMemo(
    () =>
      itemDetailDataStatus === 'success' && itemDetailData.accountAddress !== null
        ? itemDetailData.accountAddress
        : '',
    [itemDetailDataStatus, itemDetailData],
  )

  const priceConvertToExchangeRate = useCallback(
    (price: number, offerType: OfferPaymentType) => {
      return convertBalanceToCurrencyWithSymbol({
        coinPrice,
        paymentType: offerType,
        price: price.toString(),
        currency,
      })
    },
    [coinPrice, currency],
  )

  const handleAcceptOfferItem = useCallback(
    async (offerItemForDetail: IOfferItemForDetailPage) => {
      if (!itemDetailData) return
      try {
        const offerItem = await getOfferItem(itemDetailData.id, offerItemForDetail.id)
        dispatch(
          acceptDialogOpen({
            itemId: itemDetailData.id,
            collectionTitle: itemDetailData.collection.title,
            collectionAddress: itemDetailData.collection.collectionAddress,
            tokenId: itemDetailData.tokenId.toString(),
            itemTitle: itemDetailData.title,
            mediaUrl: itemDetailData.mediaUrl,
            offerItem,
            chainId: itemDetailData.collection.chainId,
            handleAcceptSummaryDialog,
          }),
        )
      } catch (e) {
        if (e instanceof Error) {
          const snapshotInError = getCurrentSnapshotInError()
          new CustomError({ name: 'ACCEPT_OFFER_ERROR', message: e.message }).sendToSentry(
            snapshotInError,
          )
        }
      }
    },
    [dispatch, getCurrentSnapshotInError, handleAcceptSummaryDialog, itemDetailData],
  )

  return (
    <ItemSectionCard className={className}>
      <div className="hidden lg:block">
        <ItemSectionCard.Title
          title={ITEM_DETAIL_TAB.offer}
          Icon={() => (
            <Image width={24} height={24} src="/img/icon/document.svg" alt="document icon" />
          )}
        />
      </div>
      <div className="h-[520px] overflow-auto md:mt-4">
        {offerDataStatus === 'success' && (
          <>
            {offerDataList?.pages.map(({ offers }, offerDataListIndex) => (
              <Fragment key={`item-detail-offerDataList-${offerDataListIndex}`}>
                {offers.length === 0 && (
                  <div className="mt-[80px] text-center text-textQuarternary">
                    {t('common:common.empty-received-offers')}
                  </div>
                )}
                {offers.length !== 0 && (
                  <>
                    {offers.map((offerData, index) => (
                      <Fragment key={`item-detail-offers-${index}`}>
                        {offerDataListIndex === 0 && index === 0 ? (
                          <HighOffer
                            price={offerData.price}
                            offerAccountAddress={offerData?.accountAddress}
                            expiredAt={offerData?.expiredAt}
                            itemOwnerAccountAddress={itemOwnerAccountAddress}
                            accountAddress={currentAccountAddress}
                            priceConvertToExchangeRate={priceConvertToExchangeRate}
                            handleAcceptOffer={() => handleAcceptOfferItem(offerData)}
                            offerType={offerData?.paymentType}
                          />
                        ) : (
                          <Offers
                            key={index}
                            index={index}
                            price={offerData?.price}
                            offerAccountAddress={offerData?.accountAddress}
                            expiredAt={offerData?.expiredAt}
                            itemOwnerAccountAddress={itemOwnerAccountAddress}
                            accountAddress={currentAccountAddress}
                            priceConvertToExchangeRate={priceConvertToExchangeRate}
                            handleAcceptOffer={() => handleAcceptOfferItem(offerData)}
                            offerType={offerData?.paymentType}
                          />
                        )}
                      </Fragment>
                    ))}
                  </>
                )}
              </Fragment>
            ))}
          </>
        )}
        {offerDataStatus === 'loading' && (
          <div className="mt-[50%]">
            <Spinner />
          </div>
        )}
        {offerDataStatus === 'error' && (
          <>
            {(error as any).response.data === 'owner not exist item' ? (
              <div className="mt-[80px] text-center text-textQuarternary">
                {t('common:common.empty-received-offers')}
              </div>
            ) : (
              <div className="mt-[80px] flex flex-col gap-3 items-center justify-center">
                <div className="text-textSecondary">{t('common:common.error-data')}</div>
                <button
                  className="py-[10px] px-3 bg-bgQuarternary rounded-sm text-sm text-textPrimary"
                  onClick={() => {
                    offerDataListRefetch()
                  }}>
                  {t('common:common.cta-label-try-again')}
                </button>
              </div>
            )}
          </>
        )}
        {hasNextPage && <div ref={targetRef} />}
      </div>
    </ItemSectionCard>
  )
}
export default ItemDetailOffer
