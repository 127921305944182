import { fulfillOrder, makeOrderWithCounterForListing } from '@/shared/utils'

import { AppThunk } from '..'
import {
  getPurchaseItemInfo,
  resetPurchaseState,
  resetSignatureState,
  setIsPurchaseError,
  setIsPurchaseFailedForUserDenied,
  setIsPurchaseSigning,
  setIsPurchaseSuccessful,
  setTxHashForPurchase,
} from '../trades'
import { getBalance } from '../wallet'

export const resetPurchaseStates = (): AppThunk => async dispatch => {
  dispatch(resetSignatureState())
  dispatch(resetPurchaseState())
}

export const purchaseDialogOpen =
  ({
    itemId,
    collectionTitle,
    collectionAddress,
    tokenId,
    chainId,
    itemTitle,
    mediaUrl,
    handlePreparingToOpenDialog,
    handleLackOfBalanceForPurchaseDialog,
    handlePurchaseSummaryDialog,
  }: {
    itemId: string
    collectionTitle: string
    collectionAddress: string
    tokenId: string
    chainId: string
    itemTitle: string
    mediaUrl: string
    handlePreparingToOpenDialog: (isPreparingToOpenDialog: boolean) => void
    handleLackOfBalanceForPurchaseDialog: (lackOfBalanceForPurchaseDialogIsOpen: boolean) => void
    handlePurchaseSummaryDialog: (purchaseSummaryDialogIsOpen: boolean) => void
  }): AppThunk =>
  async (dispatch, getState) => {
    dispatch(resetPurchaseStates())

    await dispatch(getBalance())

    const balance = {
      USDC: null,
      ETH: getState().wallet.ethBalance,
      WETH: getState().wallet.wethBalance,
      POL: getState().wallet.polBalance,
      WPOL: getState().wallet.wpolBalance,
    }

    handlePreparingToOpenDialog(true)
    await dispatch(
      getPurchaseItemInfo({
        itemId,
        collectionTitle,
        collectionAddress,
        tokenId,
        itemTitle,
        mediaUrl,
        balance,
        chainId,
      }),
    )

    handlePreparingToOpenDialog(false)

    const purchaseItemInfo = getState().purchase.purchaseItemInfo
    if (purchaseItemInfo === null || purchaseItemInfo.listingItem === null) return

    if (purchaseItemInfo !== null && purchaseItemInfo.lackOfBalance !== null) {
      handleLackOfBalanceForPurchaseDialog(true)
    } else {
      handlePurchaseSummaryDialog(true)
    }
  }

export const signForBuyOrder = (): AppThunk => async (dispatch, getState) => {
  const currentAccountAddress = getState().wallet.currentProfile?.accountAddress ?? null
  const purchaseItemInfo = getState().purchase.purchaseItemInfo

  if (currentAccountAddress === null) {
    return dispatch(setIsPurchaseError('No current account address'))
  }

  if (purchaseItemInfo === null) {
    return dispatch(setIsPurchaseError('No purchase item info'))
  }

  if (purchaseItemInfo.listingItem === null) {
    return dispatch(setIsPurchaseError('No listing item info'))
  }

  const { tokenId, listingItem } = purchaseItemInfo
  const {
    listing: { tradeAddress },
  } = listingItem

  const order = makeOrderWithCounterForListing(
    String(tokenId),
    listingItem.listing.signHash,
    listingItem,
  )

  await fulfillOrder(
    purchaseItemInfo.chainId,
    currentAccountAddress,
    order,
    tradeAddress,
    (txHash: string) => dispatch(setTxHashForPurchase(txHash)),
    () => dispatch(setIsPurchaseSuccessful(true)),
    (loading: boolean) => dispatch(setIsPurchaseSigning(loading)),
    (error: string) => dispatch(setIsPurchaseError(error)),
    () => dispatch(setIsPurchaseFailedForUserDenied(true)),
  )
}

export const retryPurchase =
  (
    handleWalletWaitingForPurchaseDialog: (walletWaitingForPurchaseDialogIsOpen: boolean) => void,
  ): AppThunk =>
  async (dispatch, getState) => {
    const isPurchaseError = getState().purchase.isPurchaseError
    const isPurchaseFailedForUserDenied = getState().purchase.isPurchaseFailedForUserDenied

    if (isPurchaseError || isPurchaseFailedForUserDenied) {
      dispatch(setIsPurchaseError(null))
      dispatch(setIsPurchaseFailedForUserDenied(false))
      handleWalletWaitingForPurchaseDialog(true)
    }
  }
