import { useTranslation } from 'react-i18next'

interface IDisconnectWalletButtonProps {
  handleDisconnectWallet: () => void
}

const DisconnectWalletButton = ({ handleDisconnectWallet }: IDisconnectWalletButtonProps) => {
  const { t } = useTranslation(['common', 'web-header'])

  return (
    <button type="button" onClick={handleDisconnectWallet} className="w-full text-center py-2">
      <p className="leading-[21px] text-sm"> {t('common:common.label-disconnect-wallet')}</p>
    </button>
  )
}

export default DisconnectWalletButton
