import { Popover, Transition } from '@headlessui/react'
import Image from 'next/image'
import { Fragment, memo, PropsWithChildren } from 'react'

import { cx } from '@/shared/utils'

interface IWalletPopoverProps {
  position: 'right' | 'left'
  displayName: string | undefined
}

const WalletPopover = ({ position, children }: PropsWithChildren<IWalletPopoverProps>) => {
  return (
    <div className="w-fit relative">
      <Popover>
        {({ open }) => (
          <>
            <Popover.Button className="p-2">
              <Image
                width={24}
                height={24}
                src="/img/icon/outline-account.svg"
                alt="account icon"
              />
            </Popover.Button>

            <Transition
              as={Fragment}
              show={open}
              enter="transition-opacity duration-75"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity duration-150"
              leaveFrom="opacity-100"
              leaveTo="opacity-0">
              <Popover.Panel className={cx(`${position}-0`, 'fixed w-full mx-auto px-4 z-50')}>
                <div className="p-4 rounded-md bg-bgTertiary">{children}</div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    </div>
  )
}

export default memo(WalletPopover)
