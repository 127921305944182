import { Space } from '@mantine/core'
import { useRouter } from 'next/router'
import { ReactNode, useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

import { ManagedDialog, ROUTE_PATH } from '@/shared/constants'
import { useBalance, useDialog, useGetSnapshotInError } from '@/shared/hooks'
import { useCollectionItemDetailQuery, useGetLikeCheck } from '@/shared/services/collections'
import { getListingList, useGetHighestPrice, useGetLowestPrice } from '@/shared/services/itemOrder'
import { useAppDispatch, useAppSelector } from '@/shared/store/hooks'
import {
  listingDialogOpen,
  listingEditOrCancelDialogOpen,
} from '@/shared/store/thunks/thunksForListing'
import { offerDialogOpen, offerEditOrCancelDialogOpen } from '@/shared/store/thunks/thunksForOffer'
import { purchaseDialogOpen } from '@/shared/store/thunks/thunksForPurchase'
import { selectCurrentAccountAddress, selectIsConnectedWallet } from '@/shared/store/wallet'
import { CustomError } from '@/shared/types'
import { convertBalanceToCurrencyWithSymbol } from '@/shared/utils'

import { TabBar } from '../common'
import { NotEnabled } from '../common/error'
import { Spinner } from '../core-ui'
import {
  ItemDetailCard,
  ItemDetailDescription,
  ItemDetailImageEnlarge,
  ItemDetailTopButtonGroup,
  ItemDetailTopInfo,
} from '../item'

const ItemDetailLayout = ({ children }: { children: ReactNode }) => {
  const { pathname, query } = useRouter()
  const dispatch = useAppDispatch()
  const { t } = useTranslation(['common', 'web-view-item'])

  const { getCurrentSnapshotInError } = useGetSnapshotInError()

  const { tokenId, collectionAddress } = query as { tokenId: string; collectionAddress: string }

  const currentAccountAddress = useAppSelector(selectCurrentAccountAddress)
  const isConnectedWallet = useAppSelector(selectIsConnectedWallet)
  const { allCoinPrice: coinPrice, currency } = useBalance()

  const realPathname = useMemo(
    () =>
      pathname
        .replace('[collectionAddress]', collectionAddress ?? '')
        .replace('[tokenId]', tokenId ?? ''),
    [collectionAddress, pathname, tokenId],
  )

  const links = useMemo(
    () => [
      {
        to: ROUTE_PATH.ITEM_DETAIL.replace(':collectionAddress', collectionAddress ?? '').replace(
          ':tokenId',
          tokenId ?? '',
        ),
        name: t('common.item-detail'),
      },
      {
        to: ROUTE_PATH.ITEM_DETAIL_ACTIVITY.replace(
          ':collectionAddress',
          collectionAddress ?? '',
        ).replace(':tokenId', tokenId ?? ''),
        name: t('common.item-history'),
      },
      {
        to: ROUTE_PATH.ITEM_DETAIL_OFFER.replace(
          ':collectionAddress',
          collectionAddress ?? '',
        ).replace(':tokenId', tokenId ?? ''),
        name: t('common.item-offers'),
      },
    ],
    [collectionAddress, t, tokenId],
  )

  const { data: itemDetailData, status: itemDetailStatus } = useCollectionItemDetailQuery(
    pathname,
    {
      collectionAddress,
      tokenId,
    },
  )

  const isCanTrade = useMemo(() => {
    return itemDetailData?.collection.isVerified
  }, [itemDetailData?.collection.isVerified])

  const [enlarge, setEnlarge] = useState(false)

  const [isListing, setIsListing] = useState<boolean>(false)
  const [isOwner, setIsOwner] = useState(false)

  const { data: likeCheckData, refetch: requestRefetchCheckLike } = useGetLikeCheck({
    tokenId,
    pathname,
    collectionAddress,
    isConnectedWallet,
  })

  const listingPriceConvertedToCoinPrice = useMemo(() => {
    if (itemDetailData === undefined || itemDetailData.listing === null) return null
    return convertBalanceToCurrencyWithSymbol({
      coinPrice,
      currency,
      paymentType: itemDetailData.listing.paymentType,
      price: itemDetailData.listing.price.toString(),
    })
  }, [coinPrice, currency, itemDetailData])

  const { data: lowestPriceListing, status: lowestPriceListingStatus } = useGetLowestPrice(
    pathname,
    itemDetailData?.id,
  )

  const { data: currentAccountHighestPriceOffer, status: currentAccountHighestPriceOfferStatus } =
    useGetHighestPrice(pathname, currentAccountAddress, isConnectedWallet, itemDetailData?.id)

  const checkItemStatus = useCallback(async () => {
    if (!itemDetailData) return

    const isOwner =
      itemDetailData.accountAddress?.toLowerCase() === currentAccountAddress?.toLowerCase()
    setIsOwner(isOwner)

    if (isOwner && isConnectedWallet) {
      try {
        const listings = await getListingList(itemDetailData.id)
        setIsListing(listings !== null && listings.length !== 0)
      } catch (error) {
        const snapshotInError = getCurrentSnapshotInError()
        new CustomError({
          name: 'GET_LISTING_LIST_ERROR',
          message: '판매 리스트를 가져오는데 실패했습니다.',
        }).sendToSentry(snapshotInError)
      }
    }
  }, [currentAccountAddress, getCurrentSnapshotInError, isConnectedWallet, itemDetailData])

  const { handleSpecificDialog, setIsPreparingToOpenDialog, setDialog } = useDialog()

  const handlePreparingToOpenDialog = useCallback(
    (isOpen: boolean) => {
      setIsPreparingToOpenDialog(isOpen)
    },
    [setIsPreparingToOpenDialog],
  )
  const handleListingFormDialog = handleSpecificDialog(ManagedDialog.listingForm)

  const handleLackOfBalanceForPurchaseDialog = handleSpecificDialog(
    ManagedDialog.lackOfBalanceForPurchase,
  )
  const handlePurchaseSummaryDialog = handleSpecificDialog(ManagedDialog.purchaseSummary)

  const handleOfferFormDialog = handleSpecificDialog(ManagedDialog.offerForm)

  const handleOfferEditOrCancelDialog = handleSpecificDialog(ManagedDialog.offerEditOrCancel)

  const handleListingEditOrCancelDialogOpen = handleSpecificDialog(
    ManagedDialog.listingEditOrCancel,
  )

  const handleCreateListing = useCallback(() => {
    if (!itemDetailData || itemDetailData.accountAddress === null) return
    dispatch(
      listingDialogOpen({
        itemId: itemDetailData.id,
        collectionAddress: itemDetailData.collection.collectionAddress,
        tokenId: itemDetailData.tokenId.toString(),
        collectionTitle: itemDetailData.collection.title,
        itemTitle: itemDetailData.title,
        mediaUrl: itemDetailData.mediaUrl,
        ownerAddress: itemDetailData.accountAddress,
        chainId: itemDetailData.collection.chainId,
        handlePreparingToOpenDialog,
        handleListingFormDialog,
      }),
    )
  }, [dispatch, handleListingFormDialog, handlePreparingToOpenDialog, itemDetailData])

  const handlePurchase = useCallback(() => {
    if (!itemDetailData) return
    dispatch(
      purchaseDialogOpen({
        itemId: itemDetailData.id,
        collectionAddress: itemDetailData.collection.collectionAddress,
        tokenId: itemDetailData.tokenId.toString(),
        collectionTitle: itemDetailData.collection.title,
        itemTitle: itemDetailData.title,
        mediaUrl: itemDetailData.mediaUrl,
        chainId: itemDetailData.collection.chainId,
        handlePreparingToOpenDialog,
        handleLackOfBalanceForPurchaseDialog,
        handlePurchaseSummaryDialog,
      }),
    )
  }, [
    dispatch,
    handleLackOfBalanceForPurchaseDialog,
    handlePreparingToOpenDialog,
    handlePurchaseSummaryDialog,
    itemDetailData,
  ])

  const handleCreateOffer = useCallback(() => {
    if (!itemDetailData) return
    dispatch(
      offerDialogOpen({
        itemId: itemDetailData.id,
        collectionAddress: itemDetailData.collection.collectionAddress,
        tokenId: itemDetailData.tokenId.toString(),
        collectionTitle: itemDetailData.collection.title,
        itemTitle: itemDetailData.title,
        mediaUrl: itemDetailData.mediaUrl,
        chainId: itemDetailData.collection.chainId,
        handlePreparingToOpenDialog,
        handleOfferFormDialog,
      }),
    )
  }, [dispatch, handleOfferFormDialog, handlePreparingToOpenDialog, itemDetailData])

  const handleClickEditOrCancelOfferDialog = useCallback(() => {
    if (!itemDetailData) return
    dispatch(
      offerEditOrCancelDialogOpen({
        itemId: itemDetailData.id,
        collectionAddress: itemDetailData.collection.collectionAddress,
        tokenId: itemDetailData.tokenId.toString(),
        collectionTitle: itemDetailData.collection.title,
        itemTitle: itemDetailData.title,
        mediaUrl: itemDetailData.mediaUrl,
        chainId: itemDetailData.collection.chainId,
        handlePreparingToOpenDialog,
        handleOfferEditOrCancelDialog,
      }),
    )
  }, [dispatch, handleOfferEditOrCancelDialog, handlePreparingToOpenDialog, itemDetailData])

  const handleClickEditOrCancelListingDialog = useCallback(() => {
    if (!itemDetailData || itemDetailData.accountAddress === null) return
    dispatch(
      listingEditOrCancelDialogOpen({
        itemId: itemDetailData.id,
        collectionAddress: itemDetailData.collection.collectionAddress,
        tokenId: itemDetailData.tokenId.toString(),
        collectionTitle: itemDetailData.collection.title,
        itemTitle: itemDetailData.title,
        mediaUrl: itemDetailData.mediaUrl,
        ownerAddress: itemDetailData.accountAddress,
        chainId: itemDetailData.collection.chainId,
        handlePreparingToOpenDialog,
        handleListingEditOrCancelDialogOpen,
      }),
    )
  }, [dispatch, handleListingEditOrCancelDialogOpen, handlePreparingToOpenDialog, itemDetailData])

  useEffect(() => {
    checkItemStatus()
  }, [checkItemStatus, currentAccountAddress])

  useEffect(() => {
    if (itemDetailStatus === 'success' && itemDetailData.status === 'ALCHEMY') {
      toast(
        <div className="text-center whitespace-pre-line">
          {t('web-view-item:view-item.data-collection-contents')}
        </div>,
      )
    }
  }, [itemDetailData?.status, itemDetailStatus, t])

  useEffect(() => {
    if (!isCanTrade && itemDetailStatus === 'success') {
      setDialog(ManagedDialog.unSupportTrade)
    }
  }, [isCanTrade, itemDetailStatus, setDialog])

  if (itemDetailStatus === 'loading')
    return (
      <div className="min-h-[100vh] flex align-center justify-center">
        <Spinner />
      </div>
    )

  if (
    tokenId === undefined ||
    itemDetailData === undefined ||
    itemDetailStatus === 'error' ||
    collectionAddress === undefined
  )
    return <NotEnabled />

  return (
    <>
      <ItemDetailImageEnlarge
        isOpen={enlarge}
        mediaUrl={itemDetailData?.mediaUrl}
        handleClose={() => setEnlarge(false)}
      />

      <Space h={24} />

      <ItemDetailCard
        item={itemDetailData}
        collectionAddress={collectionAddress}
        tokenId={tokenId}
        listingPriceConvertedToCoinPrice={listingPriceConvertedToCoinPrice}
        handleOpenEnlarge={() => setEnlarge(true)}
        requestRefetchCheckLike={requestRefetchCheckLike}
        isLike={likeCheckData?.isLike ?? false}
        handleCreateListing={handleCreateListing}
        handlePurchase={handlePurchase}
        handleCreateOffer={handleCreateOffer}
        handleClickEditOrCancelOfferDialog={handleClickEditOrCancelOfferDialog}
        handleClickEditOrCancelListingDialog={handleClickEditOrCancelListingDialog}
        lowestPriceListing={lowestPriceListing}
        lowestPriceListingStatus={lowestPriceListingStatus}
        currentAccountHighestPriceOffer={currentAccountHighestPriceOffer}
        currentAccountHighestPriceOfferStatus={currentAccountHighestPriceOfferStatus}
        isConnectedWallet={isConnectedWallet}
        isListing={isListing}
        isOwner={isOwner}
        itemStatus={itemDetailData.status}
      />

      <ItemDetailDescription className="hidden" />

      <ItemDetailTopButtonGroup
        itemId={itemDetailData.id}
        tokenId={Number(tokenId)}
        collectionAddress={collectionAddress}
        handleOpenEnlarge={() => setEnlarge(true)}
      />

      <ItemDetailTopInfo
        isOwner={isOwner}
        item={itemDetailData}
        itemTitle={itemDetailData.title}
        tokenId={String(itemDetailData.tokenId)}
        collectionTitle={itemDetailData.collection.title}
        isOriginals={itemDetailData.collection.isOriginals}
        itemOwnerAccountAddress={itemDetailData.accountAddress}
        maxTransferCount={itemDetailData.collection.maxTransferCount}
        collectionAddress={itemDetailData.collection.collectionAddress}
      />
      <div className="block">
        <TabBar links={links} pathname={realPathname} />
      </div>
      {children}
      <Space h={100} />
    </>
  )
}
export default ItemDetailLayout
