import { ShareIcon } from '@heroicons/react/24/outline'
import { Space } from '@mantine/core'
import Link from 'next/link'
import CopyToClipboard from 'react-copy-to-clipboard'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

import { VerifiedBadge } from '@/shared/assets'
import { ROUTE_PATH } from '@/shared/constants'
import { useBreakpoint, useTransferCountInfo } from '@/shared/hooks'
import { IItemDetail } from '@/shared/types'
import { cx, shortenAddress } from '@/shared/utils'

interface IItemDetailTopInfoProps {
  classNames?: string
  collectionTitle: string
  itemTitle: string
  itemOwnerAccountAddress: string | null
  collectionAddress: string
  isOwner: boolean
  tokenId: string
  isOriginals: boolean
  maxTransferCount: number | null
  item: IItemDetail
}

export default function ItemDetailTopInfo({
  item,
  classNames,
  collectionTitle,
  itemTitle,
  itemOwnerAccountAddress,
  collectionAddress,
  isOwner,
  tokenId,
  isOriginals,
  maxTransferCount,
}: IItemDetailTopInfoProps) {
  const { isLG } = useBreakpoint()
  const { t } = useTranslation('common')
  const url = `${window.location.origin}/item/${collectionAddress}/${tokenId}`

  const { remainingTransferCount } = useTransferCountInfo(item)

  const isShowTransferCounts =
    typeof remainingTransferCount === 'number' && remainingTransferCount > 0

  return (
    <>
      <div className="lg:mx-0 mx-4 mt-8 lg:mt-0">
        <div
          className={cx(
            'text-textSecondary leading-[27.5px] text-sm lg:text-xl flex justify-between',
            classNames,
          )}>
          <Link
            href={ROUTE_PATH.COLLECTION_DETAIL.replace(':collectionAddress', collectionAddress)}
            className="flex gap-1 items-center justify-center">
            {collectionTitle}
            {isOriginals && <VerifiedBadge size={isLG ? 'base' : 'sm'} />}
          </Link>
          {!isLG && (
            <CopyToClipboard
              text={url}
              onCopy={() =>
                toast(t('common:common.toast-copied-wallet-address'), {
                  toastId: 'share',
                })
              }>
              <button onClick={e => e.preventDefault()}>
                <ShareIcon className="w-6 h-6" />
              </button>
            </CopyToClipboard>
          )}
        </div>
      </div>

      <div
        title={itemTitle}
        className="font-[800] text-[24px] lg:text-[32px] leading-[30.42px] lg:leading-[41px] text-textPrimary lg:mx-0 mx-4 animated-tooltip text-ellipsis overflow-hidden">
        {itemTitle}
      </div>

      <Space h={12} />

      {isShowTransferCounts && (
        <div className="inline-flex items-center mx-4 py-2 px-2 bg-appBgTertiary gap-2 rounded-sm">
          <p className="text-sm leading-[15px] text-appTextTertiary">
            {t('common:common.item-detail-numbers-of-transfers-available-title')}
          </p>
          <div className="flex gap-1 items-center">
            <p
              className={cx(
                'text-sm leading-[18px]',
                remainingTransferCount === 0 ? 'text-appTextPrimary' : 'text-appTextAccent',
              )}>
              {t('common:common.item-detail-numbers-of-transfer-available').replace(
                '{value}',
                (remainingTransferCount ?? 0).toString(),
              )}
            </p>
            <p className="text-sm leading-[18px] text-appTextTertiary">
              /{' '}
              {t('common:common.item-detail-numbers-of-transfer-available').replace(
                '{value}',
                (maxTransferCount ?? 0).toString(),
              )}
            </p>
          </div>
        </div>
      )}

      {itemOwnerAccountAddress !== null && (
        <div className="mt-[12px] text-sm lg:text-base text-textSecondary lg:mx-0 mx-4">
          {t('common:common.label-owner')}{' '}
          <Link
            className="text-textAccent"
            href={ROUTE_PATH.ACCOUNT.replace(':accountAddress', itemOwnerAccountAddress)}>
            {isOwner ? 'you' : shortenAddress(itemOwnerAccountAddress)}
          </Link>
        </div>
      )}
    </>
  )
}
