import { useRouter } from 'next/router'
import { ReactNode } from 'react'

import { useGetCollection } from '@/shared/services/collections'

import { CollectionDetailTabBar } from '../collections'
import CollectionDetailInfo from '../collections/CollectionDetailInfo'
import { NotEnabled } from '../common/error'
import { Spinner } from '../core-ui'

const CollectionDetailLayout = ({ children }: { children: ReactNode }) => {
  const { pathname, query } = useRouter()
  const { collectionAddress } = query as { collectionAddress?: string }

  const collection = useGetCollection(pathname, collectionAddress)

  if (collection.status === 'error') return <NotEnabled />

  if (collection.status === 'loading' || !collection) {
    return (
      <div className="min-h-[100vh] flex align-center justify-center">
        <Spinner />
      </div>
    )
  }

  return (
    <div>
      <CollectionDetailInfo collection={collection.data} />
      <CollectionDetailTabBar collection={collection.data} />
      <div>{children}</div>
    </div>
  )
}

export default CollectionDetailLayout
