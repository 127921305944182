import Image from 'next/image'
import { memo, useCallback } from 'react'

import { APP_PATH_TYPE, ManagedDialog } from '@/shared/constants'
import { useDialog, useIsReactNativeWebView } from '@/shared/hooks'
import { getDeepLink, getEnvValue, LocalStorage } from '@/shared/utils'

const WalletButton = () => {
  const isWebView = useIsReactNativeWebView()

  const isDev = getEnvValue('mode') === 'development'
  const appLink = getDeepLink(APP_PATH_TYPE.HOME, isDev)

  const { setDialog } = useDialog()

  const handleConnectWalletDialogOpen = useCallback(() => {
    if (isWebView) setDialog(ManagedDialog.connectWallet)
    else {
      LocalStorage.setItem('appLink', appLink)
      setDialog(ManagedDialog.qr)
    }
  }, [appLink, isWebView, setDialog])

  return (
    <button
      type="button"
      data-dialog="connectWallet"
      onClick={handleConnectWalletDialogOpen}
      className="p-2">
      <Image width={24} height={24} src="/img/icon/outline-account.svg" alt="account icon" />
    </button>
  )
}

export default memo(WalletButton)
