/* eslint-disable @next/next/no-img-element */
import { Dialog as HlDialog, Transition } from '@headlessui/react'
import { Fragment } from 'react'

interface IItemDetailImageEnlargeProps {
  isOpen: boolean
  handleClose: () => void
  mediaUrl?: string
}

const ItemDetailImageEnlarge = ({
  isOpen,
  handleClose,
  mediaUrl,
}: IItemDetailImageEnlargeProps) => {
  if (!mediaUrl) return null

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <HlDialog as="div" className="relative z-30" onClose={() => handleClose()}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0">
          <div className="fixed inset-0 bg-bgPrimary bg-opacity-80 transition-opacity" />
        </Transition.Child>
        <div className="fixed z-10 inset-0 overflow-y-auto">
          <div className="min-h-full flex items-center justify-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 "
              enterTo="opacity-100 translate-y-0"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-4 ">
              <HlDialog.Panel className="h-full">
                <div className="flex flex-col justify-start transform transition-all h-full">
                  <div className="md:w-[800px] w-[328px]" onClick={handleClose}>
                    <img
                      src={mediaUrl}
                      onError={e => {
                        e.currentTarget.src = '/img/placeholder/item-card-replace.png'
                      }}
                      alt="item detail image"
                      className="w-full object-contain aspect-square rounded-2xl"
                    />
                  </div>
                </div>
              </HlDialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </HlDialog>
    </Transition.Root>
  )
}

export default ItemDetailImageEnlarge
