import { useRouter } from 'next/router'
import { ReactNode, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { ROUTE_PATH } from '@/shared/constants'
import { useAppSelector } from '@/shared/store/hooks'
import { selectCurrentAccountAddress } from '@/shared/store/wallet'
import { shortenAddress } from '@/shared/utils'

import { CopyAddressButton, TabBar } from '../common'
import { NotEnabled } from '../common/error'

const PublicAccountLayout = ({ children }: { children: ReactNode }) => {
  const { t } = useTranslation(['common'])
  const { query, replace, pathname } = useRouter()
  const { accountAddress } = query as { accountAddress?: string }
  const currentAccountAddress = useAppSelector(selectCurrentAccountAddress)

  const links = useMemo(() => {
    if (accountAddress === undefined) return []

    const menuLinks = [
      {
        to: ROUTE_PATH.ACCOUNT.replace(':accountAddress', accountAddress),
        name: t('common:common.label-collected'),
      },
      {
        to: ROUTE_PATH.ACCOUNT_LIKED.replace(':accountAddress', accountAddress),
        name: t('common:common.label-like'),
      },
      {
        to: ROUTE_PATH.ACCOUNT_ACTIVITY.replace(':accountAddress', accountAddress),
        name: t('common:common.label-activity'),
      },
    ]

    return menuLinks
  }, [accountAddress, t])

  const realPathname = useMemo(
    () => pathname.replace('[accountAddress]', accountAddress ?? ''),
    [accountAddress, pathname],
  )

  useEffect(() => {
    if (
      accountAddress &&
      currentAccountAddress &&
      currentAccountAddress.toLowerCase() === accountAddress.toLowerCase()
    ) {
      replace(ROUTE_PATH.CURRENT_ACCOUNT)
    }
  }, [accountAddress, currentAccountAddress, replace])

  if (accountAddress === undefined) return <NotEnabled />

  return (
    <div>
      <div className="flex items-center">
        <h1 className="text-2xl font-semibold ml-4 mr-1 my-10">{shortenAddress(accountAddress)}</h1>
        <CopyAddressButton accountAddress={accountAddress} isOnlyButton />
      </div>

      <TabBar links={links} pathname={realPathname} />
      {children}
    </div>
  )
}

export default PublicAccountLayout
